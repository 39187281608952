import React, { useState } from "react";
import Navbar from "../../components/navbar_dash/NavbarDash";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function BankAccountForm() {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [account, setAccount] = useState({
    designation: "",
    monnaie: "",
    remarques: "",
    titulaire: "",
    iban: "",
    adresse: "",
    npa: "",
    localite: "",
    caisse: false,
    facture_type: "",
    compte_statut: "",
    num_compte: "",
    int_compte: "",
    gr_comptes: "",
    compte_type: "",
  });

  const handleAccountChange = (event) => {
    const { name, value, type, checked } = event.target;
    setAccount((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAccountSubmit = (event) => {
    event.preventDefault();

    axios
      .post("/api/account", account)
      .then((response) => {
        console.log("Account created successfully:", response);
        alert("Account created successfully");
        navigate(-1);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      });
  };

  return (
    <>
      <Navbar />
      <div className="ventes">
        <div
          className=""
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: "30px",
          }}
        >
          <Link to="/bankingdash">
            <FaArrowLeftLong color="#0b2840" size={40} />
          </Link>
        </div>

        <form
          id="tasks_form"
          style={{ display: "flex", flexDirection: "column", padding:"10px 20px" }}
          onSubmit={handleAccountSubmit}
        >
          <div className="contact_textfield_row">
            <div className="contact_textfield">
              <h2>Ajouter un compte bancaire</h2>
            </div>
            <div className="contact_textfield">
              <label>
                {" "}
                <input
                  type="checkbox"
                  name="caisse"
                  checked={account.caisse}
                  onChange={handleAccountChange}
                />
                Est un compte de caisse
              </label>
            </div>
          </div>
          <section>
            <h3>Données générales</h3>
            <hr />
            <div className="contact_textfield_row">
              <div className="contact_textfield">
                <label>
                  Désignation <span style={{ color: "red" }}>*</span>{" "}
                </label>
                <input
                  type="text"
                  name="designation"
                  value={account.designation}
                  onChange={handleAccountChange}
                />
              </div>
              <div className="contact_textfield">
                <label>
                  Monnaie <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name="monnaie"
                  value={account.monnaie}
                  onChange={handleAccountChange}
                />
              </div>
              <div className="contact_textfield">
                <label>Remarques</label>
                <textarea
                  name="remarques"
                  id=""
                  cols="30"
                  rows="5"
                  value={account.remarques}
                  onChange={handleAccountChange}
                ></textarea>
              </div>
            </div>
          </section>
          <section>
            <h3>Cordonnées bancaires</h3>
            <hr />

            <div className="contact_textfield_row">
              <div className="contact_textfield">
                <label>
                  Titulaire<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="titulaire"
                  value={account.titulaire}
                  onChange={handleAccountChange}
                />
              </div>
              <div className="contact_textfield">
                <label>
                  IBAN<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="IBAN"
                  value={account.iban}
                  onChange={handleAccountChange}
                />
              </div>
            </div>
            <div className="contact_textfield_row">
              <div className="contact_textfield">
                <label>
                  Adressse du titulaire<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="adresse"
                  value={account.adresse}
                  onChange={handleAccountChange}
                />
              </div>
            </div>
            <div className="contact_textfield_row">
              <div className="contact_textfield">
                <label>
                  NPA<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name="npa"
                  value={account.npa}
                  onChange={handleAccountChange}
                />
              </div>
              <div className="contact_textfield">
                <label>
                  Localité<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="localite"
                  value={account.localite}
                  onChange={handleAccountChange}
                />
              </div>
            </div>
          </section>
          <section>
            <div
              className="contact_textfield_row"
              style={{ display:"flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <div className="contact_textfield">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name="facture_type"
                    value={account.facture_type}
                    onChange={handleAccountChange}
                  />
                  QR-facture
                </label>
                <label>
                  {" "}
                  <input
                    type="radio"
                    name="facture_type"
                    value={account.facture_type}
                    onChange={handleAccountChange}
                  />
                  Facture avec BVR
                </label>
                <label>
                  {" "}
                  <input
                    type="radio"
                    name="facture_type"
                    value={account.facture_type}
                    onChange={handleAccountChange}
                  />
                  Pas de bulletin de versement sur la facture
                </label>
              </div>
            </div>
            <hr />
            
          </section>
          <section>
            <h3>Comptabilité</h3>
            <hr />
            <div
              className="contact_textfield_row"
              style={{display:"flex", flexDirection: "row", flexWrap: "wrap" }}
            >
              <div className="contact_textfield">
                <label>
                  {" "}
                  <input
                    type="radio"
                    name="compte_statut"
                    value={account.compte_statut}
                    onChange={handleAccountChange}
                  />
                  Créer automatiquement un compte de comptabilité
                </label>
                <label>
                  {" "}
                  <input
                    type="radio"
                    name="compte_statut"
                    value={account.compte_statut}
                    onChange={handleAccountChange}
                  />
                  Lier manuellement un compte de comptabilité existant
                </label>
              </div>
            </div>
            <hr />
            <div className="contact_textfield_row">
              <div className="contact_textfield">
                <label>
                  Numéro de compte<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name="num_compte"
                  value={account.num_compte}
                  onChange={handleAccountChange}
                />
              </div>
              <div className="contact_textfield">
                <label>
                  Intitulé du compte<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="int_compte"
                  value={account.int_compte}
                  onChange={handleAccountChange}
                />
              </div>
            </div>
            <hr />
            <h3>Informations de compte</h3>
            <div className="contact_textfield_row">
              <div className="contact_textfield">
                <label>
                  Groupe de comptes<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="gr_comptes"
                  value={account.gr_comptes}
                  onChange={handleAccountChange}
                />
              </div>
            </div>
            <div className="contact_textfield_row">
              <div className="contact_textfield">
                <label>
                  Type de compte<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="compte_type"
                  value={account.compte_type}
                  onChange={handleAccountChange}
                />
              </div>
            </div>
          </section>
          {error && <div className="error">{error}</div>}

          <div style={{ padding: "3%" , gap:"15px", display:"flex", flexDirection:"row"}}>
            <button className="submit" type="submit">
              Enregistrer
            </button>
            <button className="reset" type="reset">
              Annuler
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
