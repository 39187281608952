import React from 'react';
import './FooterDash.css'

const Footer = () => {
    return (
        <>
            <div className="footer_dashboard">
                <a href="/">Fajifinance</a>
                <a href="/">Aide et assistance</a>
            </div>
        </>
    );
}

export default Footer;
