import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/navbar_dash/NavbarDash";
import "./AchatFact.css";
import { AiOutlineClose } from "react-icons/ai";
import Footer from "../../components/footer/Footer";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { TbDotsVertical } from "react-icons/tb";
import { IoMdClose } from "react-icons/io";
import SearchBar from "../../components/SearchBar/SearchBar";

const Dropdown = ({
  factureId,
  handleDropdown,
  dropdownState,
  handleFactureDuplication,
}) => {
  return (
    <div
      onClick={() => handleDropdown(factureId)}
      onMouseLeave={() => handleDropdown(null)}
      style={{ position: "relative" }} // Ensure dropdown is positioned relative to this
    >
      <TbDotsVertical />
      {dropdownState === factureId && (
        <ul
          className="dropdown"
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute", // Ensures dropdown is positioned absolutely
            top: "100%", // Adjust based on your layout
            left: "0", // Adjust based on your layout
          }}
        >
          <li>
            <button
              className="navbar__link"
              onClick={() => handleFactureDuplication(factureId)}
            >
              Dupliquer
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};
export default function AchatFact() {
  const initialSections = {
    tous: true,
    brouillon: false,
    apayer: false,
    paye: false,
    retard: false,
  };
  const [sections, setSections] = useState(initialSections);
  const toggleSection = (section) => {
    const updatedSections = { ...initialSections, [section]: true };
    for (const key in updatedSections) {
      if (key !== section) {
        updatedSections[key] = false;
      }
    }
    setSections(updatedSections);
  };
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [factures, setFactures] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [filters, setFilters] = useState({
    dateEcriture: "",
    fournisseur: "",
    echeance: "",
    reference: "",
    titre: "",
    statut: "",
    devise: "",
    brut: "",
    comptabilite: "",
    net: "",
    contact_interne: "",
    description: "",
    taux_imposition: "",
    montant: "",
    numero: "",
  });
  const [selectedFactures, setSelectedFactures] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [dropdownState, setDropdownState] = useState(null);
  const reset = () => {
    setFilters({
      dateEcriture: "",
      fournisseur: "",
      echeance: "",
      reference: "",
      titre: "",
      statut: "",
      devise: "",
      brut: "",
      comptabilite: "",
      net: "",
      contact_interne: "",
      description: "",
      taux_imposition: "",
      montant: "",
      numero: "",
    });
  };
  const handleDropdown = (factureId) => {
    setDropdownState((prev) => (prev === factureId ? null : factureId));
  };
  //add facture form
  const openContactForm = () => {
    setIsContactFormOpen(true);
  };

  const closeContactForm = () => {
    setIsContactFormOpen(false);
  };
  const fetchFactures = () => {
    axios
      .get("/api/facture")
      .then((response) => {
        setFactures(response.data);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  //fetch factures
  useEffect(() => {
    fetchFactures();
  }, []);

  //fetch contact id associated with facture
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get("/api/contTerm", {
          params: { q: searchQuery },
        });
        setContacts(response.data);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };
    if (searchQuery) {
      fetchContacts();
    }
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setIsDropdownVisible(true);
  };

  const handleContactSelect = (contactId) => {
    const contact = contacts.find((c) => c.id === contactId);
    setSelectedContact(contactId);
    setSearchQuery(contact.entreprise);
    setIsDropdownVisible(false);
  };
  //add facture
  const handleFactureSubmit = (event) => {
    event.preventDefault();

    axios
      .post(`/api/facture/${selectedContact}`)
      .then((response) => {
        navigate(`/factureform/${response.data.numero}`);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      });
  };
  const handleFactureDuplication = (facId) => {
    axios
      .post(`/api/facturedup/${facId}`)
      .then((response) => {
        fetchFactures();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      });
  };
  // filter
  const filteredFactures = factures.filter((facture) => {
    const matchesSearchQuery = facture.reference
      .toLowerCase()
      .includes(searchQuery1.toLowerCase());

    const matchesDateEcriture =
      !filters.dateEcriture ||
      facture.date_ecriture.includes(filters.dateEcriture);
    const matchesFournisseur =
      !filters.fournisseur ||
      facture.entreprise
        .toLowerCase()
        .includes(filters.fournisseur.toLowerCase());
    const matchesEcheance =
      !filters.echeance || facture.date_echeance.includes(filters.echeance);
    const matchesReference =
      !filters.reference ||
      facture.reference.toLowerCase().includes(filters.reference.toLowerCase());
    const matchesTitre =
      !filters.titre ||
      facture.titre.toLowerCase().includes(filters.titre.toLowerCase());
    const matchesStatut = !filters.statut || facture.statut === filters.statut;
    const matchesDevise = !filters.devise || facture.devise === filters.devise;
    const matchesBrut =
      !filters.brut || facture.brut.toString().includes(filters.brut);
    const matchesnet =
      !filters.net || facture.net.toString().includes(filters.net);
    const matchesnumero =
      !filters.numero || facture.numero.toString().includes(filters.numero);
    const matchescontact_interne =
      !filters.contact_interne ||
      facture.contact_interne.toString().includes(filters.contact_interne);
    const matchesdescription =
      !filters.description ||
      facture.description.toString().includes(filters.description);
    const matchestaux_imposition =
      !filters.taux_imposition ||
      facture.taux_imposition.toString().includes(filters.taux_imposition);
    const matchesmontant =
      !filters.montant || facture.montant.toString().includes(filters.montant);
    const matchesComptabilite =
      !filters.comptabilite ||
      facture.compte_de_comptabilite === filters.comptabilite;

    return (
      matchesSearchQuery &&
      matchesDateEcriture &&
      matchesFournisseur &&
      matchesEcheance &&
      matchesReference &&
      matchesTitre &&
      matchesStatut &&
      matchesDevise &&
      matchesBrut &&
      matchesComptabilite &&
      matchesnet &&
      matchesnumero &&
      matchescontact_interne &&
      matchestaux_imposition &&
      matchesmontant &&
      matchesdescription
    );
  });
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  //select factures
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedFactures(filteredFactures.map((facture) => facture.numero));
    } else {
      setSelectedFactures([]);
    }
    setSelectAll(isChecked);
  };
  const handleCheckboxChange = (factureId) => {
    if (selectedFactures.includes(factureId)) {
      setSelectedFactures(selectedFactures.filter((id) => id !== factureId));
    } else {
      setSelectedFactures([...selectedFactures, factureId]);
    }
  };
  // delete facture
  const handleDeleteFactures = () => {
    axios
      .delete("/api/facture/delete", { data: { ids: selectedFactures } })
      .then((response) => {
        if (response.status === 200) {
          setFactures(
            factures.filter(
              (facture) => !selectedFactures.includes(facture.numero)
            )
          );
          setSelectedFactures([]);
          setSelectAll(false);
        }
      })
      .catch((error) => {
        console.error("Error deleting factures:", error);
      });
  };
  return (
    <>
      <Navbar />
      <div className="ventes">
        <div className="ventes_title">
          <h1>Factures fournisseurs</h1>
          <button
            className="ventes_title_btn"
            onClick={openContactForm}
            style={{ cursor: "pointer" }}
          >
            Nouvelle facture fournisseur
          </button>
        </div>

        <section id="ventes_section1">
          <div className="ventes_head">
            <SearchBar
              searchQuery={searchQuery1}
              setSearchQuery={setSearchQuery1}
            />
            <a
              href="#"
              onClick={() => toggleSection("tous")}
              className={sections.tous ? "selected" : "not_selected"}
            >
              Tous
            </a>
            <a
              href="#"
              onClick={() => toggleSection("brouillon")}
              className={sections.brouillon ? "selected" : "not_selected"}
            >
              Brouillon
            </a>
            <a
              href="#"
              onClick={() => toggleSection("apayer")}
              className={sections.apayer ? "selected" : "not_selected"}
            >
              A payer
            </a>
            <a
              href="#"
              onClick={() => toggleSection("paye")}
              className={sections.paye ? "selected" : "not_selected"}
            >
              Payée
            </a>
            <a
              href="#"
              onClick={() => toggleSection("retard")}
              className={sections.retard ? "selected" : "not_selected"}
            >
              En retard
            </a>
          </div>
          <div className="divider"></div>{" "}
          {Object.keys(sections).map((section) => {
            if (sections[section]) {
              const filteredSectionFactures =
                section === "tous"
                  ? filteredFactures
                  : filteredFactures.filter(
                      (facture) => facture.statut.toLowerCase() === section
                    );

              return (
                <>
                  {section === "tous" && (
                    <div className="table-container">
                      <div className="table-fixed">
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </th>
                            </tr>
                            <tr>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedFactures.includes(
                                      facture.numero
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(facture.numero)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {selectedFactures.length !== 0 && (
                            <MdDelete
                              color="red"
                              size={25}
                              onClick={handleDeleteFactures}
                            />
                          )}
                        </table>
                      </div>
                      {/* Scrollable columns in the middle */}
                      <div className="table-scrollable">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                N°
                              </th>
                              <th>Date d’écriture</th>
                              <th>Fournisseur</th>
                              <th>Echéance</th>
                              <th>Référence</th>
                              <th>Titre</th>
                              <th>Statut</th>
                              <th>Devise</th>
                              <th>Brut</th>
                              <th>Net</th>
                              <th>Contact interne</th>
                              <th>Description</th>
                              <th>Taux d'imposition</th>
                              <th>Montant</th>
                              <th>Compte</th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                <input
                                  type="text"
                                  name="numero"
                                  value={filters.numero}
                                  onChange={handleFilterChange}
                                  className="input"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="dateEcriture"
                                  value={filters.dateEcriture}
                                  onChange={handleFilterChange}
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="fournisseur"
                                  value={filters.fournisseur}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="echeance"
                                  value={filters.echeance}
                                  onChange={handleFilterChange}
                                />{" "}
                              </th>

                              <th>
                                <input
                                  type="text"
                                  name="reference"
                                  value={filters.reference}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="titre"
                                  value={filters.titre}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="statut"
                                  value={filters.statut}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="Brouillon">Brouillon</option>
                                  <option value="A payer">A payer</option>
                                  <option value="Payée">Payée</option>
                                  <option value="En retard">En retard</option>
                                  <option value="En suspens">En suspens</option>
                                </select>
                              </th>
                              <th>
                                <select
                                  name="devise"
                                  value={filters.devise}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="BRL">BRL</option>
                                  <option value="CHF">CHF</option>
                                  <option value="CNY">CNY</option>
                                  <option value="EUR">EUR</option>
                                  <option value="GBP">GBP</option>
                                  <option value="JPY">JPY</option>
                                  <option value="TND">TND</option>
                                  <option value="USD">USD</option>
                                </select>
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="brut"
                                  value={filters.brut}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="net"
                                  value={filters.net}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="contact_interne"
                                  value={filters.contact_interne}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="description"
                                  value={filters.description}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="taux_imposition"
                                  value={filters.taux_imposition}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="montant"
                                  value={filters.montant}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="comptabilite"
                                  value={filters.comptabilite}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="1020">1020</option>
                                  <option value="1029">1029</option>
                                  <option value="1100">1100</option>
                                  <option value="1170">1170</option>
                                  <option value="1171">1171</option>
                                </select>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => {
                              const dateEcriture = new Date(
                                facture.date_ecriture
                              ).toLocaleDateString("en-GB");
                              const dateEcheance = new Date(
                                facture.date_echeance
                              ).toLocaleDateString("en-GB");
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Link
                                    to={`/factureform/${facture.numero}`}
                                    className="linktr"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <td
                                      style={{
                                        width: "100px",
                                      }}
                                    >
                                      {facture.numero}
                                    </td>
                                    <td>{dateEcriture}</td>
                                    <td>{facture.entreprise}</td>
                                    <td>{dateEcheance}</td>
                                    <td>{facture.reference}</td>
                                    <td>{facture.titre}</td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          facture.statut === "En suspens"
                                            ? "#0476c3"
                                            : facture.statut === "Payée"
                                            ? "#2dbc56"
                                            :facture.statut === "Brouillon" ? "#b0b0b0" :"transparent"
                                      }}
                                    >
                                      {facture.statut}
                                    </td>
                                    <td>{facture.devise}</td>
                                    <td>{facture.brut}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {facture.net}
                                    </td>
                                    <td>{facture.contact_interne}</td>
                                    <td>{facture.description}</td>
                                    <td>{facture.taux_imposition}</td>
                                    <td>{facture.montant}</td>
                                    <td>{facture.compte_de_comptabilite}</td>
                                  </Link>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-fixed-last">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              ></th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              >
                                <IoMdClose onClick={reset} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <Dropdown
                                    factureId={facture.numero}
                                    handleDropdown={handleDropdown}
                                    dropdownState={dropdownState}
                                    handleFactureDuplication={
                                      handleFactureDuplication
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {section === "brouillon" &&  <div className="table-container">
                      <div className="table-fixed">
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </th>
                            </tr>
                            <tr>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedFactures.includes(
                                      facture.numero
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(facture.numero)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {selectedFactures.length !== 0 && (
                            <MdDelete
                              color="red"
                              size={25}
                              onClick={handleDeleteFactures}
                            />
                          )}
                        </table>
                      </div>
                      {/* Scrollable columns in the middle */}
                      <div className="table-scrollable">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                N°
                              </th>
                              <th>Date d’écriture</th>
                              <th>Fournisseur</th>
                              <th>Echéance</th>
                              <th>Référence</th>
                              <th>Titre</th>
                              <th>Statut</th>
                              <th>Devise</th>
                              <th>Brut</th>
                              <th>Net</th>
                              <th>Contact interne</th>
                              <th>Description</th>
                              <th>Taux d'imposition</th>
                              <th>Montant</th>
                              <th>Compte</th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                <input
                                  type="text"
                                  name="numero"
                                  value={filters.numero}
                                  onChange={handleFilterChange}
                                  className="input"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="dateEcriture"
                                  value={filters.dateEcriture}
                                  onChange={handleFilterChange}
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="fournisseur"
                                  value={filters.fournisseur}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="echeance"
                                  value={filters.echeance}
                                  onChange={handleFilterChange}
                                />{" "}
                              </th>

                              <th>
                                <input
                                  type="text"
                                  name="reference"
                                  value={filters.reference}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="titre"
                                  value={filters.titre}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="statut"
                                  value={filters.statut}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="Brouillon">Brouillon</option>
                                  <option value="A payer">A payer</option>
                                  <option value="Payée">Payée</option>
                                  <option value="En retard">En retard</option>
                                  <option value="En suspens">En suspens</option>
                                </select>
                              </th>
                              <th>
                                <select
                                  name="devise"
                                  value={filters.devise}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="BRL">BRL</option>
                                  <option value="CHF">CHF</option>
                                  <option value="CNY">CNY</option>
                                  <option value="EUR">EUR</option>
                                  <option value="GBP">GBP</option>
                                  <option value="JPY">JPY</option>
                                  <option value="TND">TND</option>
                                  <option value="USD">USD</option>
                                </select>
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="brut"
                                  value={filters.brut}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="net"
                                  value={filters.net}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="contact_interne"
                                  value={filters.contact_interne}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="description"
                                  value={filters.description}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="taux_imposition"
                                  value={filters.taux_imposition}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="montant"
                                  value={filters.montant}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="comptabilite"
                                  value={filters.comptabilite}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="1020">1020</option>
                                  <option value="1029">1029</option>
                                  <option value="1100">1100</option>
                                  <option value="1170">1170</option>
                                  <option value="1171">1171</option>
                                </select>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => {
                              const dateEcriture = new Date(
                                facture.date_ecriture
                              ).toLocaleDateString("en-GB");
                              const dateEcheance = new Date(
                                facture.date_echeance
                              ).toLocaleDateString("en-GB");
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Link
                                    to={`/factureform/${facture.numero}`}
                                    className="linktr"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <td
                                      style={{
                                        width: "100px",
                                      }}
                                    >
                                      {facture.numero}
                                    </td>
                                    <td>{dateEcriture}</td>
                                    <td>{facture.entreprise}</td>
                                    <td>{dateEcheance}</td>
                                    <td>{facture.reference}</td>
                                    <td>{facture.titre}</td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          facture.statut === "En suspens"
                                            ? "#0476c3"
                                            : facture.statut === "Payée"
                                            ? "#2dbc56"
                                            :facture.statut === "Brouillon" ? "#b0b0b0" :"transparent"
                                      }}
                                    >
                                      {facture.statut}
                                    </td>
                                    <td>{facture.devise}</td>
                                    <td>{facture.brut}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {facture.net}
                                    </td>
                                    <td>{facture.contact_interne}</td>
                                    <td>{facture.description}</td>
                                    <td>{facture.taux_imposition}</td>
                                    <td>{facture.montant}</td>
                                    <td>{facture.compte_de_comptabilite}</td>
                                  </Link>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-fixed-last">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              ></th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              >
                                <IoMdClose onClick={reset} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <Dropdown
                                    factureId={facture.numero}
                                    handleDropdown={handleDropdown}
                                    dropdownState={dropdownState}
                                    handleFactureDuplication={
                                      handleFactureDuplication
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>}
                  {section === "apayer" &&  <div className="table-container">
                      <div className="table-fixed">
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </th>
                            </tr>
                            <tr>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedFactures.includes(
                                      facture.numero
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(facture.numero)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {selectedFactures.length !== 0 && (
                            <MdDelete
                              color="red"
                              size={25}
                              onClick={handleDeleteFactures}
                            />
                          )}
                        </table>
                      </div>
                      {/* Scrollable columns in the middle */}
                      <div className="table-scrollable">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                N°
                              </th>
                              <th>Date d’écriture</th>
                              <th>Fournisseur</th>
                              <th>Echéance</th>
                              <th>Référence</th>
                              <th>Titre</th>
                              <th>Statut</th>
                              <th>Devise</th>
                              <th>Brut</th>
                              <th>Net</th>
                              <th>Contact interne</th>
                              <th>Description</th>
                              <th>Taux d'imposition</th>
                              <th>Montant</th>
                              <th>Compte</th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                <input
                                  type="text"
                                  name="numero"
                                  value={filters.numero}
                                  onChange={handleFilterChange}
                                  className="input"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="dateEcriture"
                                  value={filters.dateEcriture}
                                  onChange={handleFilterChange}
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="fournisseur"
                                  value={filters.fournisseur}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="echeance"
                                  value={filters.echeance}
                                  onChange={handleFilterChange}
                                />{" "}
                              </th>

                              <th>
                                <input
                                  type="text"
                                  name="reference"
                                  value={filters.reference}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="titre"
                                  value={filters.titre}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="statut"
                                  value={filters.statut}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="Brouillon">Brouillon</option>
                                  <option value="A payer">A payer</option>
                                  <option value="Payée">Payée</option>
                                  <option value="En retard">En retard</option>
                                  <option value="En suspens">En suspens</option>
                                </select>
                              </th>
                              <th>
                                <select
                                  name="devise"
                                  value={filters.devise}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="BRL">BRL</option>
                                  <option value="CHF">CHF</option>
                                  <option value="CNY">CNY</option>
                                  <option value="EUR">EUR</option>
                                  <option value="GBP">GBP</option>
                                  <option value="JPY">JPY</option>
                                  <option value="TND">TND</option>
                                  <option value="USD">USD</option>
                                </select>
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="brut"
                                  value={filters.brut}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="net"
                                  value={filters.net}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="contact_interne"
                                  value={filters.contact_interne}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="description"
                                  value={filters.description}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="taux_imposition"
                                  value={filters.taux_imposition}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="montant"
                                  value={filters.montant}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="comptabilite"
                                  value={filters.comptabilite}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="1020">1020</option>
                                  <option value="1029">1029</option>
                                  <option value="1100">1100</option>
                                  <option value="1170">1170</option>
                                  <option value="1171">1171</option>
                                </select>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => {
                              const dateEcriture = new Date(
                                facture.date_ecriture
                              ).toLocaleDateString("en-GB");
                              const dateEcheance = new Date(
                                facture.date_echeance
                              ).toLocaleDateString("en-GB");
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Link
                                    to={`/factureform/${facture.numero}`}
                                    className="linktr"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <td
                                      style={{
                                        width: "100px",
                                      }}
                                    >
                                      {facture.numero}
                                    </td>
                                    <td>{dateEcriture}</td>
                                    <td>{facture.entreprise}</td>
                                    <td>{dateEcheance}</td>
                                    <td>{facture.reference}</td>
                                    <td>{facture.titre}</td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          facture.statut === "En suspens"
                                            ? "#0476c3"
                                            : facture.statut === "Payée"
                                            ? "#2dbc56"
                                            :facture.statut === "Brouillon" ? "#b0b0b0" :"transparent"
                                      }}
                                    >
                                      {facture.statut}
                                    </td>
                                    <td>{facture.devise}</td>
                                    <td>{facture.brut}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {facture.net}
                                    </td>
                                    <td>{facture.contact_interne}</td>
                                    <td>{facture.description}</td>
                                    <td>{facture.taux_imposition}</td>
                                    <td>{facture.montant}</td>
                                    <td>{facture.compte_de_comptabilite}</td>
                                  </Link>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-fixed-last">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              ></th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              >
                                <IoMdClose onClick={reset} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <Dropdown
                                    factureId={facture.numero}
                                    handleDropdown={handleDropdown}
                                    dropdownState={dropdownState}
                                    handleFactureDuplication={
                                      handleFactureDuplication
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>}
                  {section === "paye" &&  <div className="table-container">
                      <div className="table-fixed">
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </th>
                            </tr>
                            <tr>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedFactures.includes(
                                      facture.numero
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(facture.numero)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {selectedFactures.length !== 0 && (
                            <MdDelete
                              color="red"
                              size={25}
                              onClick={handleDeleteFactures}
                            />
                          )}
                        </table>
                      </div>
                      {/* Scrollable columns in the middle */}
                      <div className="table-scrollable">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                N°
                              </th>
                              <th>Date d’écriture</th>
                              <th>Fournisseur</th>
                              <th>Echéance</th>
                              <th>Référence</th>
                              <th>Titre</th>
                              <th>Statut</th>
                              <th>Devise</th>
                              <th>Brut</th>
                              <th>Net</th>
                              <th>Contact interne</th>
                              <th>Description</th>
                              <th>Taux d'imposition</th>
                              <th>Montant</th>
                              <th>Compte</th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                <input
                                  type="text"
                                  name="numero"
                                  value={filters.numero}
                                  onChange={handleFilterChange}
                                  className="input"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="dateEcriture"
                                  value={filters.dateEcriture}
                                  onChange={handleFilterChange}
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="fournisseur"
                                  value={filters.fournisseur}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="echeance"
                                  value={filters.echeance}
                                  onChange={handleFilterChange}
                                />{" "}
                              </th>

                              <th>
                                <input
                                  type="text"
                                  name="reference"
                                  value={filters.reference}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="titre"
                                  value={filters.titre}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="statut"
                                  value={filters.statut}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="Brouillon">Brouillon</option>
                                  <option value="A payer">A payer</option>
                                  <option value="Payée">Payée</option>
                                  <option value="En retard">En retard</option>
                                  <option value="En suspens">En suspens</option>
                                </select>
                              </th>
                              <th>
                                <select
                                  name="devise"
                                  value={filters.devise}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="BRL">BRL</option>
                                  <option value="CHF">CHF</option>
                                  <option value="CNY">CNY</option>
                                  <option value="EUR">EUR</option>
                                  <option value="GBP">GBP</option>
                                  <option value="JPY">JPY</option>
                                  <option value="TND">TND</option>
                                  <option value="USD">USD</option>
                                </select>
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="brut"
                                  value={filters.brut}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="net"
                                  value={filters.net}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="contact_interne"
                                  value={filters.contact_interne}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="description"
                                  value={filters.description}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="taux_imposition"
                                  value={filters.taux_imposition}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="montant"
                                  value={filters.montant}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="comptabilite"
                                  value={filters.comptabilite}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="1020">1020</option>
                                  <option value="1029">1029</option>
                                  <option value="1100">1100</option>
                                  <option value="1170">1170</option>
                                  <option value="1171">1171</option>
                                </select>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => {
                              const dateEcriture = new Date(
                                facture.date_ecriture
                              ).toLocaleDateString("en-GB");
                              const dateEcheance = new Date(
                                facture.date_echeance
                              ).toLocaleDateString("en-GB");
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Link
                                    to={`/factureform/${facture.numero}`}
                                    className="linktr"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <td
                                      style={{
                                        width: "100px",
                                      }}
                                    >
                                      {facture.numero}
                                    </td>
                                    <td>{dateEcriture}</td>
                                    <td>{facture.entreprise}</td>
                                    <td>{dateEcheance}</td>
                                    <td>{facture.reference}</td>
                                    <td>{facture.titre}</td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          facture.statut === "En suspens"
                                            ? "#0476c3"
                                            : facture.statut === "Payée"
                                            ? "#2dbc56"
                                            :facture.statut === "Brouillon" ? "#b0b0b0" :"transparent"
                                      }}
                                    >
                                      {facture.statut}
                                    </td>
                                    <td>{facture.devise}</td>
                                    <td>{facture.brut}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {facture.net}
                                    </td>
                                    <td>{facture.contact_interne}</td>
                                    <td>{facture.description}</td>
                                    <td>{facture.taux_imposition}</td>
                                    <td>{facture.montant}</td>
                                    <td>{facture.compte_de_comptabilite}</td>
                                  </Link>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-fixed-last">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              ></th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              >
                                <IoMdClose onClick={reset} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <Dropdown
                                    factureId={facture.numero}
                                    handleDropdown={handleDropdown}
                                    dropdownState={dropdownState}
                                    handleFactureDuplication={
                                      handleFactureDuplication
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>}
                  {section === "retard" &&  <div className="table-container">
                      <div className="table-fixed">
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </th>
                            </tr>
                            <tr>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={selectedFactures.includes(
                                      facture.numero
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(facture.numero)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          {selectedFactures.length !== 0 && (
                            <MdDelete
                              color="red"
                              size={25}
                              onClick={handleDeleteFactures}
                            />
                          )}
                        </table>
                      </div>
                      {/* Scrollable columns in the middle */}
                      <div className="table-scrollable">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                N°
                              </th>
                              <th>Date d’écriture</th>
                              <th>Fournisseur</th>
                              <th>Echéance</th>
                              <th>Référence</th>
                              <th>Titre</th>
                              <th>Statut</th>
                              <th>Devise</th>
                              <th>Brut</th>
                              <th>Net</th>
                              <th>Contact interne</th>
                              <th>Description</th>
                              <th>Taux d'imposition</th>
                              <th>Montant</th>
                              <th>Compte</th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "100px",
                                }}
                              >
                                <input
                                  type="text"
                                  name="numero"
                                  value={filters.numero}
                                  onChange={handleFilterChange}
                                  className="input"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="dateEcriture"
                                  value={filters.dateEcriture}
                                  onChange={handleFilterChange}
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="fournisseur"
                                  value={filters.fournisseur}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="date"
                                  name="echeance"
                                  value={filters.echeance}
                                  onChange={handleFilterChange}
                                />{" "}
                              </th>

                              <th>
                                <input
                                  type="text"
                                  name="reference"
                                  value={filters.reference}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="titre"
                                  value={filters.titre}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="statut"
                                  value={filters.statut}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="Brouillon">Brouillon</option>
                                  <option value="A payer">A payer</option>
                                  <option value="Payée">Payée</option>
                                  <option value="En retard">En retard</option>
                                  <option value="En suspens">En suspens</option>
                                </select>
                              </th>
                              <th>
                                <select
                                  name="devise"
                                  value={filters.devise}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="BRL">BRL</option>
                                  <option value="CHF">CHF</option>
                                  <option value="CNY">CNY</option>
                                  <option value="EUR">EUR</option>
                                  <option value="GBP">GBP</option>
                                  <option value="JPY">JPY</option>
                                  <option value="TND">TND</option>
                                  <option value="USD">USD</option>
                                </select>
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="brut"
                                  value={filters.brut}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="net"
                                  value={filters.net}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="contact_interne"
                                  value={filters.contact_interne}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="description"
                                  value={filters.description}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="taux_imposition"
                                  value={filters.taux_imposition}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  name="montant"
                                  value={filters.montant}
                                  onChange={handleFilterChange}
                                  className="input"
                                />
                              </th>
                              <th>
                                <select
                                  name="comptabilite"
                                  value={filters.comptabilite}
                                  onChange={handleFilterChange}
                                >
                                  <option value="">Tous</option>
                                  <option value="1020">1020</option>
                                  <option value="1029">1029</option>
                                  <option value="1100">1100</option>
                                  <option value="1170">1170</option>
                                  <option value="1171">1171</option>
                                </select>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => {
                              const dateEcriture = new Date(
                                facture.date_ecriture
                              ).toLocaleDateString("en-GB");
                              const dateEcheance = new Date(
                                facture.date_echeance
                              ).toLocaleDateString("en-GB");
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Link
                                    to={`/factureform/${facture.numero}`}
                                    className="linktr"
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <td
                                      style={{
                                        width: "100px",
                                      }}
                                    >
                                      {facture.numero}
                                    </td>
                                    <td>{dateEcriture}</td>
                                    <td>{facture.entreprise}</td>
                                    <td>{dateEcheance}</td>
                                    <td>{facture.reference}</td>
                                    <td>{facture.titre}</td>
                                    <td
                                      style={{
                                        backgroundColor:
                                          facture.statut === "En suspens"
                                            ? "#0476c3"
                                            : facture.statut === "Payée"
                                            ? "#2dbc56"
                                            :facture.statut === "Brouillon" ? "#b0b0b0" :"transparent"
                                      }}
                                    >
                                      {facture.statut}
                                    </td>
                                    <td>{facture.devise}</td>
                                    <td>{facture.brut}</td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {facture.net}
                                    </td>
                                    <td>{facture.contact_interne}</td>
                                    <td>{facture.description}</td>
                                    <td>{facture.taux_imposition}</td>
                                    <td>{facture.montant}</td>
                                    <td>{facture.compte_de_comptabilite}</td>
                                  </Link>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-fixed-last">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              ></th>
                            </tr>
                            <tr>
                              <th
                                style={{
                                  width: "50px",
                                }}
                              >
                                <IoMdClose onClick={reset} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredSectionFactures.map((facture, index) => (
                              <tr key={index}>
                                <td>
                                  <Dropdown
                                    factureId={facture.numero}
                                    handleDropdown={handleDropdown}
                                    dropdownState={dropdownState}
                                    handleFactureDuplication={
                                      handleFactureDuplication
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>}
                </>
              );
            }
            return null;
          })}
        </section>

        {isContactFormOpen && (
          <div className="contact_form_wrap">
            <div className="contact_form">
              <form
                className="contact_form_content"
                onSubmit={handleFactureSubmit}
              >
                <div className="contact_title_row">
                  <h2>Nouvelle facture fournisseur</h2>
                  <AiOutlineClose
                    color="#0B2840"
                    size={30}
                    onClick={closeContactForm}
                  />
                </div>
                <div className="contact_textfield_row">
                  <div className="contact_textfield">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      placeholder="Rechercher un contact ..."
                      onFocus={() => setIsDropdownVisible(true)}
                    />
                    {isDropdownVisible && (
                      <div className="dropdown">
                        {contacts
                          .filter((contact) =>
                            contact.entreprise
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map((contact) => (
                            <div
                              key={contact.id}
                              className="dropdown_item"
                              onClick={() => handleContactSelect(contact.id)}
                            >
                              {contact.entreprise}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>

                <div style={{ display: "flex", gap: "20px" }}>
                  <button className="submit">
                    Nouvelle facture fournisseur
                  </button>
                  <button className="reset" onClick={closeContactForm}>
                    Annuler
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
