import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from "./context/AuthContext";

const ProtectedRoute = ({ element }) => {
    const { loggedIn } = useContext(AuthContext);
    return loggedIn ? element : <Navigate to="/signin" />;
};

export default ProtectedRoute;
