import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar_dash/NavbarDash";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";

export default function FactureForm() {
  const navigate = useNavigate();
  const initialSections = {
    IBAN: true,
    paiement_manuel: false,
  };
  const { id } = useParams();
  const [sections, setSections] = useState(initialSections);
  const [contact, setContact] = useState(null);
  const [error, setError] = useState("");
  const [contacts, setContacts] = useState([]);
  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleFileChange = (ev) => {
    setFile(ev.target.files[0]);
  };

  const [facture, setFacture] = useState({
    numero: "",
    titre: "",
    date_ecriture: "",
    date_echeance: "",
    devise: "",
    reference: "",
    contact_interne: "",
    compte_de_comptabilite: "",
    taux_imposition: "",
    impot: "",
    montant: "",
    iban: "",
    frais: "",
    message: "",
    statut: "",
  });

  const toggleSection = (section) => {
    const updatedSections = { ...initialSections, [section]: true };
    for (const key in updatedSections) {
      if (key !== section) {
        updatedSections[key] = false;
      }
    }
    setSections(updatedSections);
  };

  const handleFactureChange = (event) => {
    const { name, value } = event.target;
    setFacture((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFactureSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    for (const key in facture) {
      formData.append(key, facture[key]);
    }
    formData.append("file", file);

    axios
      .put(`/api/facture/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Facture updated successfully:", response);
        alert("Facture updated successfully");
        navigate(-1);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      });
  };
  const handleStatus = (statut) => {
    setFacture((prevData) => ({
      ...prevData,
      statut: statut,
    }));
    alert("Facture status updated to: " + statut);
  };

  useEffect(() => {
    axios
      .get(`/api/facture/${id}`)
      .then((response) => {
        setFacture(response.data.factureDoc);
        setFile(facture.file);
        console.log(facture);
        axios
          .get(`/api/contact/${response.data.factureDoc.fournisseur}`)
          .then((response) => {
            setContact(response.data.contactDoc);
          })
          .catch((error) => {
            console.error("An error occurred:", error);
          });
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get("/api/contact")
      .then((response) => {
        setContacts(response.data);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);
  const dateEcriture = facture
    ? new Date(facture.date_ecriture).toLocaleDateString("en-GB")
    : "";
  return (
    <>
      <Navbar />
      <div className="ventes">
        <div className="ventes_title" style={{ flexDirection: "column" }}>
          <Link to="/achatfact">
            <FaArrowLeftLong color="#0b2840" size={20} />
          </Link>
          <h1>
            Facture fournisseur N° {facture ? facture.numero : ""} -{" "}
            {contact ? contact.entreprise : ""}
          </h1>
        </div>

        <form id="tasks_form" onSubmit={handleFactureSubmit}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "50px" }}
          >
            <section style={{padding:"0"}}>
              <div className="contact_textfield_row" style={{
                    backgroundColor:
                      facture.statut === "En suspens"
                        ? "#0476c3"
                        : facture.statut === "Payée"
                        ? "#2dbc56"
                        : facture.statut === "Brouillon"
                        ? "#b0b0b0"
                        : "transparent",
                        padding:"20px"
                  }}>
                <div
                  className="contact_textfield"
                 
                >
                  <h3>{facture ? facture.statut : ""}</h3>
                </div>
                <div className="contact_textfield">
                  <h3>Date de création: {dateEcriture}</h3>
                </div>
              </div>
              <div className="divider"></div>
              <div className="contact_textfield_row" style={{padding:"3%"}}>
                <div className="contact_textfield">
                  <h3>Montant de la facture</h3>
                  <input
                    type="text"
                    name="montant"
                    value={facture ? facture.montant : ""}
                    onChange={handleFactureChange}
                  />
                </div>
              </div>
              <div className="contact_textfield_row" style={{padding:"3%"}}>
                <div className="contact_textfield">
                  <button
                    className="saveBr"
                    onClick={() => handleStatus("Brouillon")}

                  >
                    Enregistrer le brouillon
                  </button>
                </div>
              </div>
              <br />
              <br />
            </section>
            <section>
              <h3 onClick={() => setShow1(!show1)}>
                Contact: {contact ? contact.entreprise : ""}
              </h3>
              <br />
              {show1 && (
                <>
                  {" "}
                  <div className="divider"></div>
                  <h3>Adresse: {contact ? contact.address : ""}</h3>
                  <h3>Code postal: {contact ? contact.npa : ""}</h3>
                  <h3>Localité: {contact ? contact.localite : ""}</h3>
                  <h3>Pays: {contact ? contact.pays : ""}</h3>
                </>
              )}

              <br />
              <br />
            </section>
            <section>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>N°</label>
                  <input
                    type="text"
                    name="numero"
                    value={facture ? facture.numero : ""}
                    onChange={handleFactureChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Titre</label>
                  <input
                    type="text"
                    name="titre"
                    value={facture ? facture.titre : ""}
                    onChange={handleFactureChange}
                  />
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Date d'écriture</label>
                  <input
                    type="date"
                    name="date_ecriture"
                    value={facture ? facture.date_ecriture : ""}
                    onChange={handleFactureChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Date d'échéance</label>
                  <input
                    type="date"
                    name="date_echeance"
                    value={facture ? facture.date_echeance : ""}
                    onChange={handleFactureChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Devise</label>
                  <select
                    name="devise"
                    value={facture ? facture.devise : ""}
                    onChange={handleFactureChange}
                  >
                    <option value="">--------</option>
                    <option value="BRL">BRL</option>
                    <option value="CHF">CHF</option>
                    <option value="CNY">CNY</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="JPY">JPY</option>
                    <option value="TND">TND</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Référence</label>
                  <input
                    type="text"
                    name="reference"
                    value={facture ? facture.reference : ""}
                    onChange={handleFactureChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Contact interne</label>
                  <select
                    name="contact_interne"
                    value={facture ? facture.contact_interne : ""}
                    onChange={handleFactureChange}
                  >
                    <option value="" selected>
                      --------
                    </option>

                    {contacts.map((contact) => (
                      <option key={contact.id} value={contact.entreprise}>
                        {contact.entreprise}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="contact_textfield">
                  <label>Compte de comptabilité</label>
                  <select
                    name="compte_de_comptabilite"
                    value={facture ? facture.compte_de_comptabilite : ""}
                    onChange={handleFactureChange}
                  >
                    <option value="">------</option>
                    <option value="1020">1020</option>
                    <option value="1029">1029</option>
                    <option value="1100">1100</option>
                    <option value="1170">1170</option>
                    <option value="1171">1171</option>
                  </select>
                </div>
              </div>
              <div className="divider"></div>

              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Taux d'imposition</label>
                  <input
                    type="number"
                    name="taux_imposition"
                    value={facture ? facture.taux_imposition : 0}
                    onChange={handleFactureChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Impot</label>
                  <input
                    type="text"
                    name="impot"
                    value={facture ? facture.impot : ""}
                    onChange={handleFactureChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Montant</label>
                  <input
                    type="text"
                    name="montant"
                    value={facture ? facture.montant : ""}
                    onChange={handleFactureChange}
                  />
                </div>
              </div>

              <div className="divider"></div>
              <div className="contact_textfield_row">
                <button
                  type="button"
                  className="saveBr"
                  onClick={() => handleStatus("En suspens")}
                >
                  Marquer comme en suspens
                </button>
              </div>
            </section>
            {contact && (
              <section>
                <h3 onClick={() => setShow(!show)}>Paiements</h3>
                {show && (
                  <>
                    <div className="divider"></div>
                    <div
                      className="show_buttons"
                      style={{
                        justifyContent: "flex-start",
                        gap: "40px",
                        margin: "20px 0",
                      }}
                    >
                      <button
                        onClick={() => toggleSection("IBAN")}
                        className={sections.IBAN ? "show_buttonVisible" : ""}
                        type="button"
                      >
                        IBAN
                      </button>
                      <button
                        onClick={() => toggleSection("paiement_manuel")}
                        className={
                          sections.paiement_manuel ? "show_buttonVisible" : ""
                        }
                        type="button"
                      >
                        Paiement manuel
                      </button>
                    </div>
                    {Object.keys(sections).map((section) => {
                      if (sections[section]) {
                        return (
                          <div key={section} className="section-content">
                            {section === "IBAN" && (
                              <>
                                <div className="contact_textfield_row">
                                  <div className="contact_textfield">
                                    <label>IBAN</label>
                                    <input
                                      type="number"
                                      name="iban"
                                      value={facture.iban}
                                      onChange={handleFactureChange}
                                    />
                                  </div>
                                  <div className="contact_textfield">
                                    <label>Compte de débit</label>
                                    <select
                                      name="compte_de_comptabilite"
                                      value={
                                        facture
                                          ? facture.compte_de_comptabilite
                                          : ""
                                      }
                                      onChange={handleFactureChange}
                                    >
                                      <option value="">------</option>
                                      <option value="1020">1020</option>
                                      <option value="1029">1029</option>
                                      <option value="1100">1100</option>
                                      <option value="1170">1170</option>
                                      <option value="1171">1171</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="contact_textfield_row">
                                  <div className="contact_textfield">
                                    <label>Montant</label>
                                    <input
                                      type="text"
                                      value={facture.montant}
                                      onChange={handleFactureChange}
                                    />
                                  </div>
                                  <div className="contact_textfield">
                                    <label>Devise</label>
                                    <select
                                      name="devise"
                                      value={facture ? facture.devise : ""}
                                      onChange={handleFactureChange}
                                    >
                                      <option value="">-----</option>
                                      <option value="BRL">BRL</option>
                                      <option value="CHF">CHF</option>
                                      <option value="CNY">CNY</option>
                                      <option value="EUR">EUR</option>
                                      <option value="GBP">GBP</option>
                                      <option value="JPY">JPY</option>
                                      <option value="TND">TND</option>
                                      <option value="USD">USD</option>
                                    </select>
                                  </div>
                                  <div className="contact_textfield">
                                    <label>Date d'exécution</label>
                                    <input
                                      type="date"
                                      name="date_ecriture"
                                      value={
                                        facture ? facture.date_ecriture : ""
                                      }
                                      onChange={handleFactureChange}
                                    />
                                  </div>
                                </div>
                                <div className="contact_textfield_row">
                                  <div className="contact_textfield">
                                    <label>Nom du bénéficiaire</label>
                                    <input
                                      type="text"
                                      value={contact.entreprise}
                                      onChange={handleFactureChange}
                                    />
                                  </div>
                                  <div className="contact_textfield">
                                    <label>Frais</label>
                                    <input
                                      type="number"
                                      name="frais"
                                      onChange={handleFactureChange}
                                      value={facture.frais}
                                    />
                                  </div>
                                </div>
                                <div className="contact_textfield_row">
                                  <div className="contact_textfield">
                                    <label>Addresse</label>
                                    <input
                                      type="text"
                                      value={contact.address}
                                      onChange={handleFactureChange}
                                    />
                                  </div>

                                  <div className="contact_textfield">
                                    <label>
                                      Message à l'attention du bénéficiaire
                                    </label>
                                    <textarea
                                      name="message"
                                      value={facture.message}
                                      onChange={handleFactureChange}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="contact_textfield_row">
                                  <div className="contact_textfield">
                                    <label>NPA</label>
                                    <input
                                      type="text"
                                      value={contact.npa}
                                      onChange={handleFactureChange}
                                    />
                                  </div>
                                  <div className="contact_textfield">
                                    <label>Localité</label>
                                    <input
                                      type="text"
                                      value={contact.localite}
                                      onChange={handleFactureChange}
                                    />
                                  </div>
                                </div>
                                <div className="contact_textfield_row">
                                  <div className="contact_textfield">
                                    <label>Pays</label>
                                    <input
                                      type="text"
                                      value={contact.pays}
                                      onChange={handleFactureChange}
                                    />
                                  </div>
                                </div>
                                <br />
                                {/*<div className="contact_textfield_row">
                              <div className="contact_textfield">
                                <label>
                                  <input type="checkbox" name="" id="" />
                                  Versement de salaire
                                </label>
                              </div>
                            </div> */}

                                <br />
                                <div className="contact_textfield_row">
                                  <button
                                    className="saveBr"
                                    onClick={() => handleStatus("A payer")}
                                  >
                                    Créer un ordre de paiement
                                  </button>
                                </div>
                              </>
                            )}
                            {section === "paiement_manuel" && (
                              <>
                                <div className="contact_textfield_row">
                                  <div className="contact_textfield">
                                    <label>Compte bancaire</label>
                                    <select
                                      name="compte_de_comptabilite"
                                      value={
                                        facture
                                          ? facture.compte_de_comptabilite
                                          : ""
                                      }
                                      onChange={handleFactureChange}
                                    >
                                      <option value="">------</option>
                                      <option value="1020">1020</option>
                                      <option value="1029">1029</option>
                                      <option value="1100">1100</option>
                                      <option value="1170">1170</option>
                                      <option value="1171">1171</option>
                                    </select>
                                  </div>
                                  <div className="contact_textfield">
                                    <label>Date d'exécution</label>
                                    <input
                                      type="date"
                                      name="date_ecriture"
                                      value={
                                        facture ? facture.date_ecriture : ""
                                      }
                                      onChange={handleFactureChange}
                                    />{" "}
                                  </div>
                                  <div className="contact_textfield">
                                    <label>Montant</label>
                                    <input
                                      type="text"
                                      value={facture ? facture.montant : ""}
                                      onChange={handleFactureChange}
                                    />
                                  </div>
                                </div>
                                <div className="contact_textfield_row">
                                  <div className="contact_textfield">
                                    <label>Note</label>
                                    <input
                                      type="text"
                                      name="message"
                                      value={facture.message}
                                      onChange={handleFactureChange}
                                    />
                                  </div>
                                </div>
                                <div className="contact_textfield_row">
                                  <button className="submit" type="submit">
                                    Enregistrer le paiement
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                  </>
                )}
              </section>
            )}
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "50px" }}
          >
            <section>
              <h3>Pièce comptable</h3>
              <div className="divider"></div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label htmlFor="fileInput">
                    Télécharger le fichier
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleFileChange}
                    />{" "}
                  </label>
                </div>
              </div>
              {file && (
                <div>
                  <p>{file.name}</p>
                </div>
              )}
            </section>
          </div>
          {error && <div className="error">{error}</div>}

          <div style={{ padding: "3%" }}>
            <button className="submit" type="submit">
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
