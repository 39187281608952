import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from "./context/AuthContext";

const PublicRoute = ({ element }) => {
    const { loggedIn } = useContext(AuthContext);
    return loggedIn ? <Navigate to="/userdash" /> : element;
};

export default PublicRoute;
