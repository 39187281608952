import React from "react";
import Navbar from "../../components/navbar_dash/NavbarDash";
import "./Banking.css";
import Footer from "../../components/footer_dash/FooterDash";
import { Link } from "react-router-dom";

export default function Banking() {
  return (
    <>
      <Navbar />
      <div className="show_details">
        <div className="show_title">
          <h1>Banking</h1>
          <Link
            to="/bankform"
            className="show_title_btn"
          >
            Ajouter un compte bancaire
          </Link>
        </div>

        <section id="show_section3"></section>
      </div>
      <Footer />
    </>
  );
}
