import React, { useRef, useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./NavbarDash.css";
import { FaTimes } from "react-icons/fa";
import { AiOutlineMenu, AiOutlineSetting } from "react-icons/ai";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import home from "../../assets/navbar/Home.svg";
import salaries from "../../assets/navbar/salaries.svg";
import { BsFillPersonFill } from "react-icons/bs";
import logo from "../../assets/logo.svg";

export default function Navbar() {
  const { getLoggedIn } = useContext(AuthContext);
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  const navRef = useRef();

  const [dropdown1, setDropdown1] = useState(false);
  const handleDropdown1 = (event) => {
    setDropdown1((current) => !current);
  };
  const [dropdown2, setDropdown2] = useState(false);
  const handleDropdown2 = (event) => {
    setDropdown2((current) => !current);
  };
  const [dropdown3, setDropdown3] = useState(false);
  const handleDropdown3 = (event) => {
    setDropdown3((current) => !current);
  };
  const [dropdown4, setDropdown4] = useState(false);
  const handleDropdown4 = (event) => {
    setDropdown4((current) => !current);
  };
  const [dropdown5, setDropdown5] = useState(false);
  const handleDropdown5 = (event) => {
    setDropdown5((current) => !current);
  };
  const [dropdown6, setDropdown6] = useState(false);
  const handleDropdown6 = (event) => {
    setDropdown6((current) => !current);
  };
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const [userDropdown, setUserDropdown] = useState(false);
  const handleUserDropdown = (event) => {
    setUserDropdown((current) => !current);
  };
  const [settingsDropdown, setSettingsDropdown] = useState(false);
  const handleSettingsDropdown = (event) => {
    setSettingsDropdown((current) => !current);
  };
  function Avatar(props) {
    const name = props.name || "";
    const initials = name
      .split(" ")
      .map((word) => word[0]?.toUpperCase() || "")
      .join("");

    return (
      <div
        style={{
          display: "inline-block",
          width: props.size || 50,
          height: props.size || 50,
          borderRadius: "50%",
          backgroundColor: props.color || "#ccc",
          textAlign: "center",
          fontSize: 15,
          color: "#fff",
          fontWeight: "bold",
          lineHeight: `${props.size || 50}px`,
          marginLeft: "2rem",
        }}
      >
        {initials}
      </div>
    );
  }

  async function logout() {
    await axios.get("/api/logout");
    await getLoggedIn();
    navigate("/signin");
  }

  return (
    <div className="nav1_dash">
      <div className="info_wrapper">
        <div className="info">
          <div className="info_phone">
            <Link to="/userdash">
              <img src={logo} alt="img" />
            </Link>
          </div>

          <div className="info_list">
            <div
              onMouseEnter={handleUserDropdown}
              onMouseLeave={handleUserDropdown}
            >
              <Link to="#" className="info_link">
                <BsFillPersonFill className="dropdown__icon" />{" "}
                {userInfo && (
                  <>
                    {" "}
                    {userInfo.firstname} {userInfo.lastname}
                  </>
                )}
                {userDropdown ? (
                  <GoTriangleUp className="dropdown__icon" />
                ) : (
                  <GoTriangleDown className="dropdown__icon" />
                )}
              </Link>
              {userDropdown && (
                <ul className="dropdown" style={{ flexDirection: "column" }}>
                  <li>
                    <Link className="navbar__link" to="/passwordedit">
                      Modifier le mot de passe
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/profileedit">
                      Modifier le profil et la langue
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/security">
                      Sécurité
                    </Link>
                  </li>
                  <li>
                    <button onClick={logout} className="navbar__link">
                      Déconnexion
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <div
              onMouseEnter={handleSettingsDropdown}
              onMouseLeave={handleSettingsDropdown}
            >
              <Link to="#" className="info_link">
                <AiOutlineSetting className="dropdown__icon" /> Paramètres{" "}
                {settingsDropdown ? (
                  <GoTriangleUp className="dropdown__icon" />
                ) : (
                  <GoTriangleDown className="dropdown__icon" />
                )}
              </Link>
              {settingsDropdown && (
                <ul className="dropdown" style={{ flexDirection: "column" }}>
                  <li>
                    <Link className="navbar__link" to="/vueparameters">
                      Vue d’ensemble des paramètres
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/settings">
                      Profil de l’entreprise
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/vuemodifier">
                      Modèles de document
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/vuepackages">
                      Pack/ Apps/ Utilisateurs
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/dataimport">
                      Importation / Exportation
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/vuecategorie">
                      Catégories de contact
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/commercialactivity">
                      Activités commerciales
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <>
        <nav ref={navRef}>
          <ul className="navbar__menu navbar_menu1 ">
            <div className="navbar__menu navbar_menu2">
              <li>
                <Link className="navbar__link" to="/userdash">
                  <img src={home} alt="img" />
                </Link>
              </li>
              <li>
                <Link className="navbar__link" to="/contacttous">
                  Contact
                </Link>
              </li>
              <li onMouseEnter={handleDropdown1} onMouseLeave={handleDropdown1}>
                <Link className="navbar__link" to="/offres">
                  Ventes
                  {dropdown1 ? (
                    <GoTriangleUp className="dropdown__icon" />
                  ) : (
                    <GoTriangleDown className="dropdown__icon" />
                  )}
                </Link>
                {dropdown1 && (
                  <ul className="dropdown">
                    <div className="col border">
                      <li>
                        <Link className="navbar__link" to="/offres">
                          Offres
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar__link " to="/ventescomm">
                          Commandes clients
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar__link " to="/ventes">
                          Factures
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar__link " to="/credits">
                          Crédit
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar__link " to="/analysevente2">
                          Analyse
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="navbar__link noborder"
                          to="/analysevente"
                        >
                          Autres
                        </Link>
                      </li>
                    </div>
                  </ul>
                )}
              </li>

              <li
                onMouseEnter={handleDropdown2}
                onMouseLeave={handleDropdown2}
                className="menu__item"
              >
                <Link className="navbar__link" to="/projets">
                  Projets
                  {dropdown2 ? (
                    <GoTriangleUp className="dropdown__icon" />
                  ) : (
                    <GoTriangleDown className="dropdown__icon" />
                  )}
                </Link>
                {dropdown2 && (
                  <ul className="dropdown">
                    <div className="col border">
                      <li>
                        <Link className="navbar__link" to="/projets">
                          Projet
                        </Link>
                      </li>

                      <li>
                        <Link className="navbar__link noborder" to="/projets2">
                          Temps
                        </Link>
                      </li>
                    </div>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={handleDropdown3}
                onMouseLeave={handleDropdown3}
                className="menu__item"
              >
                <Link className="navbar__link" to="/produits">
                  Produits
                  {dropdown3 ? (
                    <GoTriangleUp className="dropdown__icon" />
                  ) : (
                    <GoTriangleDown className="dropdown__icon" />
                  )}
                </Link>
                {dropdown3 && (
                  <ul className="dropdown">
                    <div className="col border">
                      <li>
                        <Link className="navbar__link" to="/produits">
                          Produit
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar__link" to="/produits2">
                          Entrées/Sortie
                        </Link>
                      </li>

                      <li>
                        <Link className="navbar__link noborder" to="/produits3">
                          Niveaux de stocks
                        </Link>
                      </li>
                    </div>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={handleDropdown4}
                onMouseLeave={handleDropdown4}
                className="menu__item"
              >
                <Link className="navbar__link" to="/achatcomm">
                  Achats
                  {dropdown4 ? (
                    <GoTriangleUp className="dropdown__icon" />
                  ) : (
                    <GoTriangleDown className="dropdown__icon" />
                  )}
                </Link>
                {dropdown4 && (
                  <ul className="dropdown">
                    <div className="col border">
                      <li>
                        <Link className="navbar__link" to="/achatcomm">
                          Commandes
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar__link" to="/achatfact">
                          Factures fournisseurs
                        </Link>
                      </li>

                      <li>
                        <Link className="navbar__link noborder" to="/achatdep">
                          Dépenses
                        </Link>
                      </li>
                    </div>
                  </ul>
                )}
              </li>
              <li className="menu__item">
                <Link className="navbar__link" to="/bankingdash">
                  Banking
                </Link>
              </li>
              <li
                onMouseEnter={handleDropdown5}
                onMouseLeave={handleDropdown5}
                className="menu__item"
              >
                <Link className="navbar__link" to="/comptabilite">
                  Comptabilité
                  {dropdown5 ? (
                    <GoTriangleUp className="dropdown__icon" />
                  ) : (
                    <GoTriangleDown className="dropdown__icon" />
                  )}
                </Link>
                {dropdown5 && (
                  <ul className="dropdown">
                    <div className="col border">
                      <li>
                        <Link
                          className="navbar__link noborder"
                          to="/comptabilite"
                        >
                          Configurer la comptabilité
                        </Link>
                      </li>
                    </div>
                  </ul>
                )}
              </li>
              <li
                onMouseEnter={handleDropdown6}
                onMouseLeave={handleDropdown6}
                className="menu__item"
              >
                <Link className="navbar__link" to="/boite">
                  Apps
                  {dropdown6 ? (
                    <GoTriangleUp className="dropdown__icon" />
                  ) : (
                    <GoTriangleDown className="dropdown__icon" />
                  )}
                </Link>
                {dropdown6 && (
                  <ul className="dropdown">
                    <div className="col border">
                      <li>
                        <Link className="navbar__link " to="/boite">
                          Boite de réception
                        </Link>
                      </li>
                      <li>
                        <Link className="navbar__link noborder" to="/tasks">
                          Taches
                        </Link>
                      </li>
                    </div>
                  </ul>
                )}
              </li>
              <li className="menu__item">
                <Link className="navbar__link" to="/salaires">
                  Salaires
                  <img src={salaries} alt="img" />{" "}
                </Link>
              </li>
              {/* <Avatar
                size={45}
                color="#999"
                name={`${userInfo.firstname || ""} ${
                  userInfo.lastname || ""
                }`.trim()}
              />*/}
            </div>

            {/* <div className="search_navbar">
              <input type="text" placeholder="Recherche" />
            </div>*/}
            <button className="navbar__btn close" onClick={showNavbar}>
              <FaTimes />
            </button>
          </ul>
        </nav>
        <div className="row">
          <Link to="/userdash" className="home">
            <img src={logo} alt="img" />
          </Link>
          <button className="navbar__btn open " onClick={showNavbar}>
            <AiOutlineMenu />
          </button>
        </div>
      </>
    </div>
  );
}
