import React, { useState } from "react";
import Navbar from "../../components/navbar_dash/NavbarDash";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
export default function ContactForm() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [contactFormData, setContactFormData] = useState({
    type_contact: "",
    entreprise: "",
    complement: "",
    email1: "",
    phone1: "",
    email2: "",
    mobile: "",
    address: "",
    npa: "",
    localite: "",
    pays: "",
    categorie: "",
    langue: "",
    secteur: "",
    siteweb: "",
    skype: "",
    voie: "",
    proprietaire: "",
    interlocuteur: "",
    nombredecollaborateurs: "",
    num_immat: "",
    num_tva_intra: "",
    num_tva: "",
    fax: "",
    phone2: "",
    remarques: "",
  });

  const handleContactChange = (event) => {
    const { name, value } = event.target;
    setContactFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleContactSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/api/contact", contactFormData)
      .then((response) => {
        console.log("Contact added successfully sent successfully:", response);
        alert("Contact added successfully sent successfully");
        navigate(-1);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      });
  };
  return (
    <>
      <Navbar />
      <div className="ventes">
        <div className="ventes_title" style={{flexDirection:"column"}}>
          <Link to="/contacttous">
            <FaArrowLeftLong color="#0b2840" size={20}/>
          </Link>
          <h1>Nouveau contact</h1>
        </div>

        <form id="tasks_form" onSubmit={handleContactSubmit}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "50px" }}
          >
            <section>
              <h4>Données de base</h4>
              <div className="divider"></div>

              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <h4>
                    Type de contact <span>*</span>
                  </h4>
                  <div className="contact_textfield_row2">
                    <label>
                      <input
                        type="radio"
                        name="type_contact"
                        value="Entreprise"
                        checked={contactFormData.type_contact === "Entreprise"}
                        onChange={handleContactChange}
                      />
                      Entreprise
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="type_contact"
                        value="Privé"
                        checked={contactFormData.type_contact === "Privé"}
                        onChange={handleContactChange}
                      />
                      Privé
                    </label>
                  </div>
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>
                    Entreprise <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="entreprise"
                    value={contactFormData.entreprise}
                    onChange={handleContactChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Complément</label>
                  <input
                    type="text"
                    name="complement"
                    value={contactFormData.complement}
                    onChange={handleContactChange}
                  />
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Adresse</label>
                  <textarea
                    name="address"
                    value={contactFormData.address}
                    onChange={handleContactChange}
                    rows={5}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="contact_textfield"
                    style={{ flexDirection: "row", gap: "5px" }}
                  >
                    <div className="contact_textfield">
                      <label>Code postal</label>
                      <input
                        className="npa"
                        type="number"
                        name="npa"
                        value={contactFormData.npa}
                        onChange={handleContactChange}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="contact_textfield">
                      <label>Ville</label>
                      <input
                        type="text"
                        name="localite"
                        value={contactFormData.localite}
                        onChange={handleContactChange}
                      />
                    </div>
                  </div>
                  <div className="contact_textfield_row">
                    <div className="contact_textfield">
                      <label>Pays</label>
                      <select
                        name="pays"
                        value={contactFormData.pays}
                        onChange={handleContactChange}
                      >
                        <option value="">Pays</option>
                        <option selected value="Suisse">
                          Suisse
                        </option>
                        <option value="tunisie">Tunisie</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <br />
            </section>
            <section>
              <h4>Communication</h4>
              <div className="divider"></div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>E-mail 1</label>
                  <input
                    type="email"
                    name="email1"
                    value={contactFormData.email1}
                    onChange={handleContactChange}
                  />
                </div>{" "}
                <div className="contact_textfield">
                  <label>E-mail 2</label>
                  <input
                    type="email"
                    name="email2"
                    value={contactFormData.email2}
                    onChange={handleContactChange}
                  />
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Téléphone 1</label>
                  <input
                    type="text"
                    name="phone1"
                    value={contactFormData.phone1}
                    onChange={handleContactChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Téléphone 2</label>
                  <input
                    type="text"
                    name="phone2"
                    value={contactFormData.phone2}
                    onChange={handleContactChange}
                  />
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Mobile</label>
                  <input
                    type="text"
                    name="mobile"
                    value={contactFormData.mobile}
                    onChange={handleContactChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Fax</label>
                  <input
                    type="text"
                    name="fax"
                    value={contactFormData.fax}
                    onChange={handleContactChange}
                  />
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Site web</label>
                  <input
                    type="text"
                    name="siteweb"
                    value={contactFormData.siteweb}
                    onChange={handleContactChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Skype</label>
                  <input
                    type="text"
                    name="skype"
                    value={contactFormData.skype}
                    onChange={handleContactChange}
                  />
                </div>
              </div>
            </section>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "50px" }}
          >
            <section>
              <h4>Informations supplémentaires</h4>
              <div className="divider"></div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Interlocuteur</label>
                  <input
                    placeholder="Interlocuteur"
                    type="text"
                    name="interlocuteur"
                    value={contactFormData.interlocuteur}
                    onChange={handleContactChange}
                  />
                </div>
                <div className="contact_textfield">
                  <label>Propriétaire</label>
                  <input
                    placeholder="Propriétaire"
                    type="text"
                    name="proprietaire"
                    value={contactFormData.proprietaire}
                    onChange={handleContactChange}
                  />
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Langue</label>
                  <select
                    name="langue"
                    value={contactFormData.langue}
                    onChange={handleContactChange}
                  >
                    <option value="">Langue</option>
                    <option selected value="ang">
                      Anglais
                    </option>
                    <option value="ar">Arabe</option>
                    <option value="fr">Français</option>
                  </select>
                </div>
                <div className="contact_textfield">
                  <label>Voie de correspondance</label>
                  <select
                    name="voie"
                    value={contactFormData.voie}
                    onChange={handleContactChange}
                  >
                    <option value="">Voie de correspondance</option>
                    <option selected value="email">
                      E-mail
                    </option>
                    <option value="telephone">Téléphone</option>
                  </select>
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Remarques</label>
                  <textarea
                    name="remarques"
                    value={contactFormData.remarques}
                    onChange={handleContactChange}
                    rows={5}
                  />
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Catégorie</label>
                  <input
                    placeholder="Catégorie"
                    type="text"
                    name="categorie"
                    value={contactFormData.categorie}
                    onChange={handleContactChange}
                  />
                </div>
              </div>

              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Secteur</label>
                  <input
                    placeholder="Secteur d'activité"
                    type="text"
                    name="secteur"
                    value={contactFormData.secteur}
                    onChange={handleContactChange}
                  />
                </div>
              </div>
            </section>
            <section>
              <h4>Autres informations de contact</h4>
              <div className="divider"></div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>Nombre de collaborateurs</label>
                  <input
                    placeholder="Nombre de collaborateurs"
                    name="nombredecollaborateurs"
                    value={contactFormData.nombredecollaborateurs}
                    onChange={handleContactChange}
                    type="number"
                    style={{ width: "95%" }}
                  />
                </div>
                <div className="contact_textfield">
                  <label>N° d'immatriculation au registre du commerce</label>
                  <input
                    placeholder="N° d'immatriculation au registre du commerce"
                    name="num_immat"
                    value={contactFormData.num_immat}
                    onChange={handleContactChange}
                    type="text"
                    style={{ width: "95%" }}
                  />
                </div>
              </div>
              <div className="contact_textfield_row">
                <div className="contact_textfield">
                  <label>N° de TVA</label>
                  <input
                    placeholder="Numéro TVA"
                    name="num_tva"
                    value={contactFormData.num_tva}
                    onChange={handleContactChange}
                    type="text"
                    style={{ width: "95%" }}
                  />
                </div>
                <div className="contact_textfield">
                  <label>N° de TVA intracommunautaire</label>
                  <input
                    placeholder="Numéro TVA intracommunautaire"
                    name="num_tva_intra"
                    value={contactFormData.num_tva_intra}
                    onChange={handleContactChange}
                    type="text"
                    style={{ width: "95%" }}
                  />
                </div>
              </div>
            </section>
          </div>
          {error && <div className="error">{error}</div>}

          <div
            style={{
              padding: "3%",
            }}
          >
            <button className="submit" type="submit">
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
