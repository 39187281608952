import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import "./Navbar3Dash.css";
const Navbar3 = () => {
  return (
    <div className="nav3_dash">
      <div className="info_wrapper">
        <div className="info">
          <div className="info_phone">
            <Link to="/userdash">
              <img src={logo} alt="img" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar3;
