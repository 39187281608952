import React, { useState } from "react";
import axios from "axios";
import "./Contact.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Test from "../../components/test/Test";
import mail from "../../assets/c_mail.svg";
import tel from "../../assets/c_tel.svg";
import map from "../../assets/c_map.svg";
import BTTButton from "../../components/BTTButton/BTTButton";

export default function Contact() {
  const [error, setError] = useState("");

  const countryCodes = [
    { name: "Choisir le code pays ... ", code: "" },
    { name: "Tunisia", code: "+216" },
    { name: "United States", code: "+1" },
    { name: "France", code: "+33" },
    { name: "Germany", code: "+49" },
  ];
  
  const [formData, setFormData] = useState({
    lastname: "",
    firstname: "",
    email: "",
    codepays: "",
    phonenumber: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...formData,
      createdAt: Date.now()
    };
    axios.post("/api/send_message", dataToSubmit)
      .then(response => {
        console.log("Message sent successfully:", response);
        window.location.reload();
      })
      .catch(error => {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
      });
  };

  return (
    <>
      <Navbar />
      <div id="contact_form">
        <div className="contact_form_col1">
          <h6>Contactez-nous</h6>
          <h4>
            Nous serons heureux de répondre à toutes vos questions sur Faji.
          </h4>
          <div className="contact_form_col1_row">
            <div className="mail_wrapper">
              <img src={mail} alt="img" />
            </div>
            <div>
              <p>E-Mail :</p>
              <span>contact@faji.com.tn</span>
            </div>
          </div>
          <div className="contact_form_col1_row">
            <div className="tel_wrapper">
              <img src={tel} alt="img" />
            </div>
            <div>
              <p>Téléphone :</p>
              <span>+216 71 808 862 </span>
            </div>
          </div>
          <div className="contact_form_col1_row">
            <div className="address_wrapper">
              <img src={map} alt="img" />
            </div>
            <div>
              <p>Adresse :</p>
              <span>71 , Rue Alain Savary, 1003 Tunis, Tunisie.</span>
            </div>
          </div>
        </div>
        <div className="contact_form_col2">
          <h2>Voulez-vous nous contacter ?</h2>
          <h3>
            Il vous suffit de remplir le formulaire pour que nous vous
            répondions dans les plus brefs délais.
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="input_field">
              <input
                type="text"
                placeholder="Nom"
                name="lastname"
                id="lastname"
                value={formData.lastname}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Prénom"
                name="firstname"
                id="firstname"
                value={formData.firstname}
                onChange={handleChange}
              />
            </div>
            <div className="input_field">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-mail"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="input_field">
              <select
                name="codepays"
                id="codepays"
                value={formData.codepays}
                onChange={handleChange}
              >
                {countryCodes.map((country, index) => (
                  <option key={index} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>
              <input
                type="number"
                placeholder="Numéro de téléphone"
                name="phonenumber"
                id="phonenumber"
                value={formData.phonenumber}
                onChange={handleChange}
              />
            </div>
            <div className="input_field">
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            {error && <div className="error">{error}</div>}

            <button type="submit">Envoyer un message</button>
          </form>
        </div>
      </div>
      <Test />
      <BTTButton />
      <Footer />
    </>
  );
}
