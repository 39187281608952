import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../../components/navbar_dash/NavbarDash";
import "./Boite.css";
import Footer from "../../components/footer_dash/FooterDash";
import img2 from "../../assets/group802.png";
import img3 from "../../assets/group943.png";
import SearchBar from "../../components/SearchBar/SearchBar";

export default function Boite() {
  const initialSections = {
    boite: true,
    archive: false,
    tous: false,
  };
  const [sections, setSections] = useState(initialSections);
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const [uploads, setUploads] = useState([]);
  const [selectedUpload, setSelectedUpload] = useState(null); // New state

  const toggleSection = (section) => {
    const updatedSections = { ...initialSections, [section]: true };
    for (const key in updatedSections) {
      if (key !== section) {
        updatedSections[key] = false;
      }
    }
    setSections(updatedSections);
  };

  const handleFileChange = (ev) => {
    setFile(ev.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setShow(false);
      fetchUpload();
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const fetchUpload = async () => {
    await axios
      .get("/api/upload")
      .then((response) => {
        setUploads(response.data);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };
  const handleRowClick = (upload) => {
    setSelectedUpload(upload); // Set the selected upload when a row is clicked
    document
      .getElementById("show_section2")
      .scrollIntoView({ behavior: "smooth" }); // Optional: Scroll to the section
  };
  useEffect(() => {
    fetchUpload();
  }, []);
  const archived = uploads.filter((file) => file.archive);
  const nonArchived = uploads.filter((file) => !file.archive);
  return (
    <>
      <Navbar />
      <div className="show_details">
        <div className="show_title">
          <h1>Boîte de réception</h1>
          <button
            className="show_title_btn"
            style={{ cursor: "pointer" }}
            onClick={() => setShow(!show)}
          >
            Télécharger
          </button>
        </div>
        {show && (
          <section className="show_section3">
            <div className="section-content">
              <div className="boite_modif">
                <label htmlFor="fileInput">
                  Select file{" "}
                  <img
                    src={img3}
                    alt="img"
                    color="#000"
                    style={{ marginTop: "5px", cursor: "pointer" }}
                  />
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </label>

                <br />
                <p> {file.filename} <br />
                  Taille de fichier max.: 12 MB <br />
                  Formats de fichiers acceptés:
                  doc,docx,gif,jpeg,jpg,pdf,png,ppt,pptx,xls,xlsx,svg
                </p>
                <button onClick={handleFileUpload} className="upload_btn">
                  Upload
                </button>
              </div>
            </div>
          </section>
        )}
        <div className="show_sections">
          <section id="show_section1">
            <div className="section-content">
              <div className="show_section">
                <div className="show_buttons">
                  <SearchBar
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                  />
                  <a
                    href="#"
                    onClick={() => toggleSection("boite")}
                    className={
                      sections.boite
                        ? "boite_button1"
                        : "boite_button1_selected"
                    }
                  >
                    Boîte de réception
                  </a>
                  <a
                    href="#"
                    onClick={() => toggleSection("archive")}
                    className={
                      sections.archive
                        ? "boite_button1"
                        : "boite_button1_selected"
                    }
                  >
                    Archives
                  </a>
                  <a
                    href="#"
                    onClick={() => toggleSection("tous")}
                    className={
                      sections.tous ? "boite_button1" : "boite_button1_selected"
                    }
                  >
                    Tous
                  </a>
                </div>
                <div className="divider"></div>
                <table className="boite_table">
                  <tbody>
                    <tr className="thead">
                      <td className="boite_button2">Date</td>
                      <td className="boite_button2">Nom du fichier</td>
                      <td className="boite_button2"></td>
                    </tr>
                    {sections.boite &&
                      nonArchived.map((upload, index) => (
                        <tr
                          key={index}
                          onClick={() => handleRowClick(upload)}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              selectedUpload === upload ? "#efefef" : "inherit",
                          }}
                        >
                          <td>
                            {new Date(upload.date_of_upload).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td>{upload.filename}</td>
                          <td>
                            <a
                              href={`${upload.file_url}`}
                              style={{ color: "black", fontWeight: "bold" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Télécharger
                            </a>
                          </td>
                        </tr>
                      ))}

                    {sections.archive &&
                      archived.map((upload, index) => (
                        <tr
                          key={index}
                          onClick={() => handleRowClick(upload)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            {new Date(upload.date_of_upload).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td>{upload.filename}</td>
                          <td>
                            <a
                              href={`${upload.file_url}`}
                              style={{ color: "black", fontWeight: "bold" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Télécharger
                            </a>
                          </td>
                        </tr>
                      ))}

                    {sections.tous &&
                      uploads.map((upload, index) => (
                        <tr
                          key={index}
                          onClick={() => handleRowClick(upload)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>
                            {new Date(upload.date_of_upload).toLocaleDateString(
                              "en-GB"
                            )}
                          </td>
                          <td>{upload.filename}</td>
                          <td>
                            <a
                              href={`${upload.file_url}`}
                              style={{ color: "black", fontWeight: "bold" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Télécharger
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section id="show_section2">
            <div className="section-content">
              <div
                className="show_section"
                style={{
                  padding: "5% 0",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  minHeight: "800px",
                  width: "100%",
                  position: "relative",
                }}
              >
                {selectedUpload ? (
                  <iframe
                    src={selectedUpload.file_url}
                    title={selectedUpload.filename}
                    style={{
                      border: "none",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                    }}
                  />
                ) : (
                  <img src={img2} alt="img" />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}
