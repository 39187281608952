import React, { useState , useContext} from "react";
import "./Sign.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import QRCode from "react-qr-code";

const VerifyCode = ({ email }) => {
  const { getLoggedIn } = useContext(AuthContext);
  const { loggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      code: code,
    };
    try {
      await axios.post("/api/verifycode", payload);
      await getLoggedIn();

      navigate("/userdash");
      console.log("User Logged in successfully");
      console.log(loggedIn);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };

  return (
    <>
    
      <form onSubmit={handleSubmit}>
      <h1>Welcome to Faji</h1>
        <div className="input_field">
          <h6>Enter Verification Code <span style={{color:"red"}}>*</span></h6>

          <input
            type="number"
            name="code"
            id="code"
            placeholder="* * * * * *"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        {error && <div className="error">{error}</div>}
        <button type="submit">Send</button>
      </form>
    </>
  );
};
export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [showQRCode, setShowQRCode] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const [secret, setSecret] = useState("");


  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      email: email,
      password: password,
    };

    try {
      const Response = await axios.post("/api/login", payload);
      setShowQRCode(true);
      setShowLogin(false);
      setSecret(Response.data.secret);
    } catch (error) {
      if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}    }
  };

  const accountName = "Faji Finance";
  const uri = `otpauth://totp/${encodeURIComponent(
    accountName
  )}?secret=${secret}&issuer=${encodeURIComponent(accountName)}`;

  return (
    <>
    {showLogin&&(
       <div className="form_wrapper">
        <div className="div1"></div>
        <div className="fform">
          <form onSubmit={handleSubmit}>
            <h1>Welcome to Faji</h1>
            <div className="input_field">
              <h6>Email <span style={{color:"red"}}>*</span></h6>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Email here"
                required
              />
            </div>
            <div className="input_field">
              <h6>Password <span style={{color:"red"}}>*</span></h6>
              <input
                type="password"
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
            </div>
            {error && <div className="error">{error}</div>}

            <button type="submit">Login</button>
            <p>
              Don't have an account ?{" "}
              <Link to="/signup" className="link">
                Sign Up ?
              </Link>
            </p>
          </form>
        </div>
      </div>
    )}
      {showQRCode && (
        <div className="form_wrapper">
          <div className="fform">
            <QRCode value={uri} size={150} />
            <VerifyCode email={email} />
          </div>
        </div>
      )}
    </>
  );
}
