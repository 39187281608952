import React, { useState , useContext} from "react";
import "./PwChange.css";
import Navbar3 from "../../components/navbar3_dash/Navbar3Dash";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../context/AuthContext";

const PwChange = () => {
  const { getLoggedIn } = useContext(AuthContext);
  const { loggedIn } = useContext(AuthContext);
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  const [pswd, setPswd] = useState("");
  const [newPswd, setNewPswd] = useState("");
  const [confirmedNewPswd, setConfirmedNewPswd] = useState("");

  const [error, setError] = useState("");

  const handleSubmit = async (e) => {

    e.preventDefault();
    const payload = {
      email: userInfo.email,
      currentPassword: pswd,
      newPassword: newPswd,
      confirmNewPassword: confirmedNewPswd,
    };
    try {
      await axios.post("/api/mdpwd", payload);
      await getLoggedIn();
      navigate("/userdash");
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };
  return (
    <>
      <Navbar3 />
      <div className="pw_change settings">
        <div className="pw_header">
          <h1>Modifier le mot de passe</h1>
          <a href="/userdash">Retour à l’aperçu</a>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="pw_form settings_input">
            <div className="input_settings">
              <label htmlFor="company_name">
                Mot de passe actuel <b style={{ color: "red" }}>*</b>
              </label>
              <input 
              type="password" 
              name="currentPassword" 
              id="currentPassword" 
              value={pswd}
              onChange={(e) => setPswd(e.target.value)}
              placeholder="*  *  *  *  *  *"
              required
              />
            </div>
            <div className="input_settings">
              <label htmlFor="company_name">
                Nouveau mot de passe <b style={{ color: "red" }}>*</b>
              </label>
              <input 
              type="password" 
              name="newPassword" 
              id="newPassword" 
              value={newPswd}
              onChange={(e) => setNewPswd(e.target.value)}
            
              placeholder="*  *  *  *  *  *"
              required
              />
            </div>
            <div className="input_settings">
              <label htmlFor="company_name">
                Répéter le nouveau mot de passe{" "}
                <b style={{ color: "red" }}>*</b>
              </label>
              <input 
              type="password" 
              name="confirmNewPassword" 
              id="confirmNewPassword" 
              value={confirmedNewPswd}
              onChange={(e) => setConfirmedNewPswd(e.target.value)}
              placeholder="*  *  *  *  *  *"
              required
              />
            </div>
          </div>
          {error && <div className="error">{error}</div>}

          <button className="submit" style={{ marginLeft: "5%" }} type="submit">
            Modifier le mot de passe
          </button>
          <br />
          <br />
        </form>
      </div>
    </>
  );
};

export default PwChange;
