import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { BsFillPersonFill } from "react-icons/bs";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import logo from "../../assets/logo.svg";
import { IoIosNotifications } from "react-icons/io";
import "./Navbar2Dash.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const Navbar2 = () => {
  const { getLoggedIn } = useContext(AuthContext);
  const { userInfo } = useContext(AuthContext);
  const navigate = useNavigate();

  async function logout() {
    await axios.get("/api/logout");
    await getLoggedIn();
    navigate("/signin");
  }

  const [userDropdown, setUserDropdown] = useState(false);
  const handleUserDropdown = (event) => {
    setUserDropdown((current) => !current);
  };
 

  const [activeLink, setActiveLink] = useState("");

  const handleLinkClick = (to) => {
    setActiveLink(to);
  };
  const location = useLocation();

  // Use the location pathname to set the initial active link
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);
  return (
    <div className="nav2_dash">
      <div className="info_wrapper">
        <div className="info">
          <div className="info_phone">
            <Link to="/userdash">
              <img src={logo} alt="img" />
            </Link>
          </div>
          <div className="info_list reduce">
            <Link
              className={`info_link ${
                activeLink === "/salaires" ? "active_info_link" : ""
              }`}
              to="/salaires"
              onClick={() => handleLinkClick("/salaires")}
            >
              Salaires
            </Link>
            <Link
              className={`info_link ${
                activeLink === "/employee" ? "active_info_link" : ""
              }`}
              to="/employee"
              onClick={() => handleLinkClick("/employee")}
            >
              Employés 1
            </Link>
            <Link
              className={`info_link ${
                activeLink === "/entreprise" ? "active_info_link" : ""
              }`}
              to="/entreprise"
              onClick={() => handleLinkClick("/entreprise")}
            >
              Entreprise +
            </Link>
            <Link
              className={`info_link ${
                activeLink === "/documents" ? "active_info_link" : ""
              }`}
              to="/documents"
              onClick={() => handleLinkClick("/documents")}
            >
              Documents
            </Link>
          </div>
          <br />
          <div className="info_list">
            <div
              onMouseEnter={handleUserDropdown}
              onMouseLeave={handleUserDropdown}
            >
              <Link to="#" className="info_link">
                <BsFillPersonFill className="dropdown__icon" />{" "}
                {userInfo.firstname} {userInfo.lastname}
                {userDropdown ? (
                  <GoTriangleUp className="dropdown__icon" />
                ) : (
                  <GoTriangleDown className="dropdown__icon" />
                )}
              </Link>
              {userDropdown && (
                <ul className="dropdown" style={{ flexDirection: "column" }}>
                  <li>
                    <Link className="navbar__link" to="/passwordedit">
                      Modifier le mot de passe
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/profileedit">
                      Modifier le profil et la langue
                    </Link>
                  </li>
                  <li>
                    <Link className="navbar__link" to="/security">
                      Sécurité
                    </Link>
                  </li>
                  <li>
                    <button onClick={logout} className="navbar__link">
                      Déconnexion
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <Link to="/notif">
              {" "}
              <IoIosNotifications className="dropdown__icon" />{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar2;
