import React from 'react';
import './ConfPolicy.css'
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import BTTButton from "../../components/BTTButton/BTTButton";

const ConfPolicy = () => {
    return (
        <>
            <Navbar/>
            <div className="section1_policy">
                <h1>Politique de confidentialité</h1>
                <h2>Faji AG</h2>
                <p>Faji AG, société sise Alte Jonastrasse 24, 8640 Rapperswil, Suisse (ci-après le «Fournisseur»), est l'auteur de la présente Politique de confidentialité. Cette Politique de confidentialité s'applique à tous les utilisateurs des services du Fournisseur, dans la mesure où des données personnelles sont traitées dans le cadre de la prestation de ces services. Sont notamment concernés les Clients ayant conclu un contrat avec le Fournisseur pour les services de ce dernier, leurs employés et les visiteurs du site web. En outre, le Fournisseur peut déclarer la Politique de confidentialité applicable à d'autres partenaires contractuels sur une base contractuelle. Par souci de simplicité, toutes les personnes concernées par le traitement des données sont ci-après dénommées «Clients».</p>
                <p>Le Fournisseur s'efforce de traiter les informations personnelles de ses Clients de manière soigneuse et consciencieuse. Le Fournisseur est responsable de la collecte, du traitement, de la transmission, du stockage et de la protection des informations personnelles de ses Clients et veille au respect de la loi fédérale sur la protection des données («LPD») en ce qui concerne les données protégées des Clients suisses; il se conforme par ailleurs au règlement général sur la protection des données de l'UE («RGPD») dans la mesure les données protégées des Clients de l'espace européen sont concernées.</p>
                <h4>Le consentement donné par le Client à cette Politique de confidentialité peut être révoqué à tout moment avec effet immédiat (cf. point 11, dernier paragraphe).</h4>
                <h3>1. Coordonnées</h3>
                <p>Le responsable du traitement des données est:</p>
                <h3>Faji AG</h3>
                <h5>+216 71 808 862 <br />contact@faji.com.tn <br />FAJI Finance – 71 , Rue Alain Savary, 1003 Tunis, Tunisie.  </h5>
                <h3>2. Droit applicable</h3>
                <p>Le traitement des données par le fournisseur est régi par le droit suivant:</p>
                <h4>Données des Clients suisses</h4>
                <p>Le traitement des données des Clients suisses est exclusivement régi par le droit suisse, en particulier la loi fédérale sur la protection des données (LPD, RS 235.1) et l’ordonnance relative à la loi fédérale sur la protection des données (RS 235.11). Le règlement général sur la protection des données de l'UE (RGPD) n’est pas applicable. Le RGPD reste applicable (i) s’il est expressément prévu pour certains domaines de cette Politique de confidentialité et (ii) si son application est obligatoire pour les données des Clients suisses en raison de circonstances particulières.</p>
                <h4>Données des Clients de la zone UE</h4>
                <p>Outre le droit suisse, le règlement (UE) 2016/679 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des données) s’applique pour le traitement des données des Clients établis dans l'UE. Voir aussi le point 13 (Dispositions supplémentaires pour les Clients établis dans l’espace européen).</p>
                <h3>3. Caractéristique et étendue de la collecte de données personnelles</h3>
                <h4>Lors d'une visite de notre site web (sans identification)</h4>
                <p>Lorsque les Clients naviguent sur le site Internet du Fournisseur sans accéder au domaine sécurisé par une identification, le serveur web enregistre automatiquement leurs données de navigation. Cela inclut, entre autres, l'adresse IP de l'appareil utilisé, qui est toutefois anonymisée par Google avant d'être enregistrée, de sorte qu'elle ne peut plus être associée à un Client spécifique. Google utilise pour cela la méthode _anonymizeIp(). En outre, cette collecte inclut des informations sur le type de navigateur, le fournisseur de services Internet et le système d'exploitation utilisés.</p>
                <h4>Lors de l'utilisation du logiciel en ligne Faji (avec identification)</h4>
                <p>En outre, au cours de la période d'utilisation gratuite ainsi que lors de l'utilisation payante du logiciel Faji, toutes les données saisies par le Client dans le domaine sécurisé par une identification dans le cadre du processus d'inscription et de l'utilisation du logiciel font l'objet d'un enregistrement. Ceci est notamment le cas lorsque le Client s'inscrit, passe des commandes, remplit des formulaires en ligne, participe à des sondages ou à des concours, communique avec le Fournisseur en ligne ou hors ligne, ou entre en contact avec le Fournisseur via des réseaux sociaux, des blogs ou d'autres médias interactifs.</p>
                <p>En règle générale, sont alors recueillies les données personnelles (nom, adresse, adresse e-mail) ainsi que les informations requises pour utiliser le service concerné. De plus amples informations sur le traitement des données et ses finalités sont consultables dans l'Annexe A à l'accord de traitement des données.</p>
                <h4>En saisissant ses informations, le Client consent expressément à ce que ses informations personnelles soient traitées, utilisées et transmises dans le cadre et l'étendue des objectifs décrits dans la présente Politique de confidentialité.</h4>
                <h4>Partage de données avec des tiers /   comptables</h4>
                <p>Le Client a la possibilité de partager ses données avec des tiers, notamment son   comptable personnel, et ce directement ou dans le cadre du programme de partenariat avec des   comptable s développé par le Fournisseur. En accordant des droits d'accès, le Client accepte que le Fournisseur mette toutes ses données à la disposition d'un tiers (le   comptable , par exemple) ou permette l'accès à celles-ci. Le Client conserve à tout moment le contrôle total des droits d'accès des tiers à ses données et peut restreindre ou refuser cet accès à tout moment.</p>
                <p>En outre, le Fournisseur autorise certains tiers (les   comptable s, notamment) à ouvrir un compte Faji en tant que Clients. Dans ce cas, le tiers (  comptable ou autre) gère les droits d'accès en tant que Client et peut les accorder, les restreindre ou les refuser à des tiers. Toutefois, le Fournisseur se réserve le droit de divulguer des données spécifiques à des tiers autorisés dans des cas individuels justifiés.</p>
                <h4>Comptabilité des salaires</h4>
                <p>Lorsque le Client utilise le logiciel de comptabilité des salaires optionnel du Fournisseur, des données personnelles concernant les employés du Client sont nécessairement transmises au Fournisseur. Le Fournisseur traite ces données avec le soin approprié et assure leur protection conformément aux normes spécifiées dans la présente Politique de confidentialité. Le Client déclare son consentement et dégage le Fournisseur de toute réclamation éventuelle. L'obtention du consentement des employés est de la responsabilité du Client. Le Client reconnaît par ailleurs qu'il est le seul responsable de l'information de ses collaborateurs en ce qui concerne le stockage, l'utilisation, le traitement et le transfert éventuels des données par le Fournisseur conformément aux directives énoncées dans la présente Politique de confidentialité. Si un employé du Client ne consent pas au traitement des données prévu par Faji, le Client est responsable de la suppression des données concernant cet employé dans le cloud de Faji.</p>
                <h4>Marketplace d'applications / Modules complémentaires de tiers</h4>
                <p>Le Fournisseur met à la disposition du Client une interface («API») de communication avec le logiciel de fournisseurs tiers. Le Client a ainsi la possibilité d'intégrer divers packs ou offres supplémentaires de fournisseurs tiers («add-ons») au logiciel Faji. Le Client peut commander des modules complémentaires sur la marketplace d'applications du Fournisseur. En outre, le Client peut autoriser d'autres fournisseurs tiers à utiliser l'interface avec son compte Faji. Sauf convention contraire expresse, une relation contractuelle concernant l'utilisation de modules complémentaires tiers est établie exclusivement entre le Client et le fournisseur tiers.</p>
                <p>Si des droits d’accès sont nécessaires pour utiliser un add-on, le Client, lors de la commande de l'intégration de l’add-on en question, marque expressément son accord d’octroyer tous les droits d’accès nécessaires. Le Fournisseur est alors en droit de fournir toutes les données Client nécessaires à l'utilisation du module complémentaire ou d'autoriser l'accès à ces données. Le Client conserve à tout moment le contrôle total des droits d'accès du fournisseur tiers à ses données et peut restreindre ou refuser cet accès à tout moment. Le Client accepte que le Fournisseur ou un fournisseur tiers échange des données avec ce fournisseur tiers dans le cadre de l'utilisation d'autres add-ons.</p>
                <p>En passant commande d'un module complémentaire, le Client accepte les conditions générales et la déclaration de protection des données du fournisseur tiers concerné. Le Fournisseur n'assume aucune responsabilité quant au traitement des données par le fournisseur tiers.</p>
                <h4>Services de conseil par des fournisseurs tiers</h4>
                <p>Le Fournisseur propose à ses Clients des services de conseil fournis par des fournisseurs tiers. Les données suivantes sont transmises à ces fournisseurs tiers afin qu'ils puissent vérifier l'autorisation du Client et disposent des coordonnées nécessaires: nom / raison sociale de l'entreprise; adresse (rue, Code postal, Ville, compléments d'adresse); contrats conclus entre le Fournisseur et le Client; numéro(s) de téléphone; adresse(s) e-mail. Le Client peut en outre prendre connaissance de la politique de confidentialité du fournisseur tiers dans la version actuellement en vigueur.</p>
                <h4>Fonctionnalités de banking</h4>
                <p>Dans le cadre de l'utilisation des fonctions optionnelles de banking mises à disposition par le Fournisseur et notamment de la connexion du compte Faji du Client avec une banque, des données sont échangées entre le Fournisseur et la banque concernée. Les interfaces bancaires peuvent être mises à disposition directement en collaboration avec la banque concernée ou via la plateforme bLink de SIX BBS AG. Les données traitées incluent notamment des informations bancaires et relatives aux paiements telles que l'IBAN et les informations de compte. À des fins de prévention et de correction d'erreurs, le Fournisseur stocke les données de connexion suivantes pendant une période d'un mois lors de l'utilisation des fonctions bancaires: abréviation de la base de données, BIC de la banque, étapes techniques (authentification, fichier envoyé, fichier récupéré, déconnexion par exemple) ainsi que la date et l'heure de la connexion.</p>
                <p>Le Fournisseur peut envoyer aux utilisateurs des interfaces bancaires ou aux employés disposant d'un droit d'accès des notifications relatives aux interfaces bancaires existantes ou à la banque connectée. Des données personnelles peuvent être traitées à cette fin. Les notifications spécifiques à la banque peuvent être révoquées via le lien «Se désinscrire».</p>
                <h4>Autres fonctions associées à des partenaires</h4>
                <p>Dans le cadre de l'utilisation de fonctions optionnelles associées à des partenaires, et notamment de la connexion du compte du Client avec le système d’un partenaire, des données sont échangées entre le Fournisseur et le partenaire concerné.</p>
                <h3>4. Sécurité des données</h3>
                <p>Le Fournisseur prend des mesures de sécurité techniques et organisationnelles conformément aux normes reconnues du secteur afin de protéger les données personnelles qu'il collecte contre tout(e) manipulation involontaire, illégale ou non autorisée, suppression, altération, accès, transmission ou utilisation et de manière à éviter toute perte partielle ou complète de ces données. Les serveurs du Fournisseur sont situés en Suisse. Certains services peuvent être traités via des serveurs situés dans d'autres pays avec un niveau approprié de protection des données. Le cas échéant, les exigences de la LPD et du RGPD sont pleinement respectées à tout moment. La connexion aux serveurs du Fournisseur est sécurisée par un protocole de chiffrement SSL. Le Fournisseur effectue régulièrement des sauvegardes des données Client. Afin d'éviter toute perte de données, y compris dans les cas extrêmes (destruction du centre de données suite à un tremblement de terre, par exemple), les sauvegardes chiffrées sont stockées parallèlement dans plusieurs autres centres de données en Suisse et à l'étranger. Les exigences de la LPD et du RGPD sont pleinement respectées à tout moment. Les mesures de sécurité du Fournisseur sont continuellement mises à jour et renforcées en fonction de l'évolution technologique. Le Fournisseur décline toute responsabilité en cas de perte de données ou de consultation et d'utilisation des données par des tiers. Le Fournisseur n'est pas en mesure de garantir la sécurité des transmissions de données sur Internet. La transmission de données par e-mail comporte notamment un risque d'intrusion par des tiers. L'accès est néanmoins protégé à l’aide du protocole HTTPS. Si le Client le souhaite, il a la possibilité d’opter pour l’authentification à deux facteurs à tout moment.</p>
                <h3>5. Objectif du traitement des données personnelles / Destinataire des données</h3>
                <p>Le Fournisseur traite les données collectées afin de pouvoir améliorer constamment les produits et services souhaités, gérer l'utilisation et l'accès souhaité aux applications, produits et informations, soigner la relation commerciale avec les Clients, analyser et améliorer les performances de l'offre afin d'identifier, de prévenir ou d'enquêter sur des activités illégales ou de communiquer aux Clients des offres, des informations ou du matériel de marketing sur les produits ou services que le Fournisseur, sur la base des données collectées, estime susceptibles d'intéresser les Clients. Les données peuvent également être transmises à des sociétés partenaires, des fournisseurs de services, des prestataires externes sélectionnés, des institutions et/ou des autorités publiques légalement autorisées en Suisse et à l'étranger pour être traitées, stockées ou utilisées aux fins mentionnées précédemment. Si le traitement ou le stockage d'informations personnelles est réalisé dans un pays ne garantissant pas un niveau de protection des données approprié et équivalent au niveau de protection offert en Suisse, le Fournisseur exige du prestataire qu’il se conforme pleinement, dans le cadre d’une obligation contractuelle, aux dispositions pertinentes de la LPD (ou du RGPD si les données concernées sont celles de Clients établis dans l’espace européen).</p>
                <p>Le Fournisseur sous-traite certains des processus et services mentionnés ci-dessus à des prestataires contractuellement tenus de se conformer à la réglementation relative à la protection des données. Ces entreprises sont basées dans l’UE ou en Suisse. Il s'agit en particulier d'entreprises opérant dans le domaine des services informatiques, de paiement, de facturation, de recouvrement et de conseil ainsi que de fournisseurs de services d'impression, de distribution et de marketing auxquels le Fournisseur peut faire appel dans le cadre du traitement des commandes.</p>
                <h3>6. Echange de données avec le Groupe Mobilière</h3>
                <p>En acceptant les Conditions générales et la présente Politique de confidentialité du Fournisseur, le Client déclare expressément son consentement au transfert de ses données à la société mère du fournisseur et aux sociétés affiliées (ci-après conjointement «Mobilière») conformément à la présente section. Celles-ci incluent:</p>
                <ul>
                    <li>les compagnies d'assurance du Groupe Mobilière1</li>
                    <li>les autres entreprises du Groupe Mobilière2</li>
                </ul>
                <p>1 Les compagnies d'assurances du Groupe Mobilière comprennent: Mobilière Suisse Société d’assurances SA, Mobilière Suisse Société d’assurances sur la vie SA, Protekta Assurance de protection juridique et SwissCaution SA.</p>
                <p>2 Vous trouverez la liste des autres entreprises appartenant au Groupe Mobilière à l'adresse suivante:  https://www.mobiliere.ch/la-mobiliere/a-propos/entreprises-du-groupe-mobiliere</p>
                <p>L'échange de données entre le Fournisseur et la Mobilière permet notamment d'exploiter davantage les synergies existantes avec la société mère. Les données personnelles particulièrement sensibles ne sont en aucun cas transmises.</p>
                <p>La Mobilière s'engage à traiter toutes les données dont elle a connaissance exclusivement dans le cadre de la législation en matière de protection des données et à respecter les règles de sécurité en matière de protection des données. La Mobilière est tenue de garder confidentielles les données dont elle a connaissance.</p>
                <p>Le Fournisseur et le Groupe Mobilière sont autorisés à traiter les données dans le cadre des activités suivantes ou à les transmettre aux fins suivantes:</p>
                <ul>
                    <li>Comparaison des données Clients: Les données de base des Clients font l'objet d'une comparaison à des fins statistiques. La comparaison permet d'évaluer le nombre de Clients communs existants, l'évolution de cette proportion dans le temps et la répartition géographique des Clients communs</li>
                    <li>Analyse des segments de marché: Les données peuvent être traitées à des fins d'analyse de segments de marché. L'objectif principal de la segmentation du marché est d'identifier les différents groupes de Clients afin de développer des programmes de marketing spécifiques à chaque segment (analyse de la structure de la Clientèle).</li>
                    <li>Echange d'informations: Les données peuvent être traitées dans le but d'échanger des informations entre le Fournisseur et la Mobilière. L'objectif principal est de pouvoir améliorer constamment les produits et services pour le Client, de gérer l'utilisation et l'accès souhaité aux applications, produits et informations, de soigner la relation commerciale avec le Client, et de suivre et d'améliorer la performance des offres.</li>
                    <li>bjectifs de marketing et d'analyse: Les données peuvent être échangées afin de communiquer au Client des offres, des informations ou du matériel de marketing sur des produits ou des services qui, sur la base des données collectées, sont susceptibles d'intéresser le Client.</li>
                </ul>
                <h3>7. Cookies</h3>
                <p>Les cookies permettent de rendre la visite sur le site web du Fournisseur plus facile, plus agréable et plus pertinente. Les cookies sont des fichiers contenant des informations que le navigateur web enregistre automatiquement sur le disque dur de l'ordinateur lorsque le Client visite le site web et utilise le produit du Fournisseur.</p>
                <p>Le Client a la possibilité de modifier les paramètres de sécurité de son navigateur afin de bloquer ou de désactiver les cookies. Toutefois, il est alors possible que certains services du Fournisseur ne puissent plus être utilisés dans leur intégralité.</p>
                <h4>Outils de suivi et d'analyse / Réseaux sociaux</h4>
                <p>L'utilisation des offres numériques du Fournisseur est mesurée et évaluée à l'aide de différents systèmes techniques fournis principalement par des prestataires externes, comme Google Analytics. Ces mesures peuvent être effectuées de manière anonyme ou non. Il est alors possible que les données collectées soient transmises par le Fournisseur ou par les fournisseurs tiers de ces systèmes techniques à des tiers – en Suisse ou à l'étranger – en vue d'un traitement ultérieur. L’outil d’analyse le plus utilisé est Google Analytics, un service proposé par Google Inc. Par conséquent, les données collectées peuvent en principe être transmises à un serveur de Google aux États-Unis (ou dans un autre pays déterminé par Google).</p>
                <p>Le site web du Fournisseur utilise Google Analytics, un service d'analyse web fourni par Google Inc., dont le siège est situé au 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis («Google»). Google Analytics utilise des « cookies »: des fichiers texte installés sur l'ordinateur du Client afin de pouvoir analyser la façon dont celui-ci utilise le site web. Les informations générées par les cookies concernant l'utilisation du site web (y compris l'adresse IP, qui est anonymisée par Google avant d'être enregistrée afin qu'elle ne puisse pas être attribuée au Client) sont transmises à un serveur de Google aux États-Unis (ou dans un autre pays déterminé par Google) et y sont stockées. Google utilise ces informations pour analyser l'utilisation du site web, réaliser des rapports concernant les activités du site à l'attention du Fournisseur ainsi que dans le cadre d’autres prestations liées à l’utilisation du site et d’Internet. Par ailleurs, Google peut transmettre ces informations à des tiers si la loi le prescrit ou dans la mesure où des tiers exploitent ces données pour le compte de Google. En aucun cas Google n’associera l'adresse IP du Client à d’autres données de Google. </p>
                <p>Le site web du Fournisseur utilise la fonction «données démographiques» de Google Analytics, qui permet d'obtenir des informations concernant l'âge, le sexe ainsi que les centres d'intérêt des Clients. Ces données proviennent de la publicité ciblée par centres d'intérêt de Google et des données relatives aux visiteurs de fournisseurs tiers. Ces données ne peuvent en aucun cas être attribuées à une personne identifiable. Les Clients peuvent désactiver cette fonction à tout moment dans les paramètres publicitaires de leur compte Google ou interdire de manière générale la collecte de leurs données par Google Analytics. On trouvera dans la déclaration de protection des données de Google de plus amples informations à ce sujet: https://support.google.com/analytics/answer/6004245?hl=fr</p>
                <p>Si le Client ne souhaite pas que son activité en ligne soit disponible pour Google Analytics, il a la possibilité d'installer le module complémentaire de navigateur pour la désactivation de Google Analytics: https://support.google.com/analytics/answer/181881?hl=fr Ce module empêche le code JavaScript de Google Analytics (ga.js, analytics.js et dc.js) inséré sur les sites web de partager des informations concernant leur visite avec Google Analytics. Néanmoins, l'installation de ce module n’empêche pas les propriétaires de sites d'utiliser d'autres outils pour effectuer des analyses. Ainsi, des données peuvent encore être envoyées aux sites web ou à d'autres services d'analyse d'audience Internet.Enfin, le Fournisseur collecte via son site web dans des fichiers journaux de serveur certaines informations que le navigateur Internet du Client transmet automatiquement. Ceux-ci incluent, entre autres, l'agent utilisateur (type de navigateur et version du navigateur, système d'exploitation utilisé), les informations d'en-tête http (URL de référence, adresse IP de l'appareil utilisé), l'heure de la demande du serveur et l'état de connexion. Ces fichiers journaux du serveur sont uniquement regroupés avec d'autres sources de données pour l'analyse des erreurs.</p>
                <h4>Technologies à des fins publicitaires</h4>
                <p>Le site web du Fournisseur utilise les fonctions de Google Analytics Remarketing conjointement avec les fonctions multi-appareils de Google AdWords et Google DoubleClick. Ces services sont fournis par Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis («Google»).</p>
                <p>Cette fonction permet d'associer les groupes cibles publicitaires créés avec Google Analytics Remarketing aux fonctions multi-appareils de Google AdWords et Google DoubleClick. Ainsi, les annonces publicitaires ciblées et personnalisées qui ont été adaptées aux besoins du Client sur un appareil (un téléphone portable, par exemple) en fonction de ses habitudes d’utilisation et de navigation peuvent également être affichées sur un autre appareil (une tablette ou un PC, par exemple)</p>
                <p>Si le Client a donné son consentement à Google, Google associe à cette fin l'historique du navigateur web et celui des applications au compte Google du Client. Les mêmes annonces publicitaires personnalisées peuvent ainsi s'afficher sur tous les appareils sur lesquels le Client se connecte avec son compte Google. Dans le cadre de cette fonction, Google Analytics recueille des identifiants d’utilisateurs connectés à Google,</p>
                <p>qui sont temporairement associés aux données Google Analytics du Fournisseur dans le but de définir et de créer des groupes-cibles pour la publicité multi-appareils. Le Client peut bloquer de façon permanente le remarketing multi-appareils en désactivant la personnalisation des annonces dans son compte Google sous le lien suivant: https://www.google.com/settings/ads/onweb/ Plus d’informations à ce sujet sont fournies dans la déclaration de protection des données de Google à l’adresse suivante: https://www.google.com/policies/technologies</p>
                <p>Le site web du fournisseur utilise également l’outil de publicité Google AdWords, géré par Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis. Le Fournisseur utilise dans le cadre de Google AdWords ce que l’on appelle le suivi des conversions. Lorsque le Client clique sur une annonce placée par Google, un cookie de suivi des conversions est défini. Les cookies sont de petits fichiers texte que le navigateur Internet stocke sur l’ordinateur du Client. Ces cookies perdent leur validité après 30 jours et ne sont pas utilisés pour identifier personnellement les utilisateurs.</p>
                <p>Si le Client visite le site web du Fournisseur et que le cookie n’a pas encore expiré, Google et le Fournisseur peuvent voir que le Client a cliqué sur l’annonce et a été redirigé vers cette page. Google informe le Fournisseur du nombre total d’utilisateurs qui ont cliqué sur son annonce et ont été redirigés vers son site web doté d’une balise de suivi des conversions. Cependant, le Fournisseur ne reçoit aucune information lui permettant d'identifier personnellement le Client. Pour éviter que des cookies soient enregistrés, le Client a la possibilité de modifier le paramétrage de son navigateur. Toutefois, le Fournisseur attire l'attention du Client sur le fait que le Client pourra alors ne pas être en mesure d'utiliser toutes les fonctions de ce site web dans leur intégralité. Par ailleurs, le Client peut empêcher le suivi des conversions en désactivant le cookie correspondant dans les paramètres utilisateur de son navigateur Internet. Plus d’informations à ce sujet sont fournies dans les règles de confidentialité de Google à l’adresse suivante:</p>
                <p>https://www.google.de/policies/privacy/ Le site web du fournisseur utilise également l’outil d’analyse Pixel de Facebook, géré par Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, États-Unis. Cet outil permet de retracer le comportement des visiteurs redirigés vers le site web du Fournisseur après avoir cliqué sur une annonce publicitaire Facebook.</p>
                <p>Fournisseur peut ainsi évaluer l’efficacité des annonces publicitaires publiées sur Facebook à des fins de statistiques et d’études de marché et optimiser ses futures mesures publicitaires. Les données collectées restent anonymes pour le Fournisseur et ne lui permettent pas de déduire l'identité du Client. Elles sont toutefois sauvegardées et traitées par Facebook, ce qui fait qu’un lien peut être établi avec le profil utilisateur correspondant et que Facebook peut utiliser ces données à des fins de publicité pour son propre compte, conformément à la Politique d’utilisation des données de Facebook. Facebook peut, de cette manière, diffuser des annonces publicitaires sur ses pages ou sur des pages tierces. Le Fournisseur n’a aucun contrôle sur l’utilisation qui est faite de ces données. Le Client peut bloquer le remarketing de façon permanente en désactivant la fonction de remarketing «Audiences personnalisées» dans les préférences publicitaires de son compte Facebook à l'adresse ci-dessous. Pour ce faire, il doit être connecté à Facebook:</p>
                <p>https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen Si le Client n'a pas de compte Facebook, il peut désactiver la publicité adaptée à l’utilisateur de Facebook sur le site Internet de l’European Interactive Digital Advertising Alliance à l'adresse suivante: http://www.youronlinechoices.com /de/praferenzmanagement/ Plus d’informations à ce sujet sont fournies dans la politique d’utilisation des données de Facebook à l’adresse suivante: https://www.facebook.com/about/privacy/</p>
                <h4>Intégration d'offres provenant de tiers / Réseaux sociaux</h4>
                <p>Les offres numériques du Fournisseur sont connectées de diverses manières à des fonctions et des systèmes de tiers, par exemple par l’intégration de plug-ins de réseaux sociaux tiers comme Facebook, Google Plus ou encore Twitter. Si le Client dispose d'un compte utilisateur auprès de ces tiers, ces derniers ont également la possibilité de mesurer et d’analyser l'utilisation de nos offres numériques. D’autres données personnelles telles que l’adresse IP, les paramètres personnels du navigateur ainsi que d'autres paramètres peuvent alors être transmises à ces tiers et enregistrées par eux. Le Fournisseur n'exerce aucun contrôle sur l’utilisation de telles données personnelles collectées par des tiers et décline toute responsabilité concernant cette utilisation. Au demeurant, le Fournisseur ne connaît pas en détail les données transmises aux fournisseurs tiers, l'endroit vers lequel elles sont transmises, et ne sait pas si elles sont anonymisées ou non.</p>
                <p>Le site web du Fournisseur utilise des plug-ins de YouTube gérés par YouTube LLC, 901 Cherry Ave., San Bruno, CA 94066, États-Unis. Le plug-in de YouTube établit une connexion avec les serveurs de YouTube, qui sont ainsi informés des pages consultées par le Client sur le site web du Fournisseur. <br /> Si le Client est connecté à son compte YouTube, YouTube peut directement rattacher ses habitudes de navigation à son profil personnel. Pour éviter cela, il suffit au Client de se déconnecter de son compte utilisateur YouTube. <br /> Plus d’informations à ce sujet sont fournies dans les règles de confidentialité de YouTube à l’adresse suivante: https://www.google.de/intl/de/policies/privacy</p>
                <h4>Autres outils</h4>
                <p>Le site web du fournisseur utilise le service de cartographie Google Maps, géré par Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, États-Unis. Si le Client utilise les fonctions de Google Maps, son adresse IP est enregistrée par Google et généralement transmise à un serveur de Google aux États-Unis. Le Fournisseur n’a aucun contrôle sur ce transfert de données.</p>
                <p>Plus d’informations à ce sujet sont fournies dans les règles de confidentialité de Google à l’adresse suivante: https://www.google.de/intl/de/policies/privacy/</p>
                <h3>8. Profilage / Prise de décision automatisée</h3>
                <p>Le profilage est le traitement automatisé de données personnelles visant à analyser ou à prédire certains aspects ou comportements personnels. Il permet par exemple de fournir au Client un soutien et des conseils plus spécifiques ou de mieux adapter les offres à ses besoins individuels.</p>
                <p>Une «décision individuelle automatisée» est une décision prise de manière totalement automatique, autrement dit sans intervention humaine significative, et qui a des effets juridiques négatifs ou d’autres effets néfastes similaires pour le Client. En règle générale, le Fournisseur ne prend pas de décisions individuelles automatisées. Le Fournisseur informera spécifiquement le Client s'il utilise des décisions individuelles automatisées dans des cas particuliers. Le cas échéant, le Client aura la possibilité de faire contrôler manuellement cette décision par un employé du Fournisseur.</p>
                <h3>9. Newsletter et marketing par e-mail</h3>
                <p>Lorsque le Client s'inscrit à une des newsletters proposées sur le site web du Fournisseur, ce dernier lui demande son adresse e-mail et d’autres informations qui lui permettent de vérifier qu'il est bien le titulaire de l’adresse e-mail indiquée et qu'il accepte de recevoir la newsletter (procédure de double confirmation ou «double opt-in»). La newsletter permet au Client de recevoir de façon régulière un panel d’offres et de recommandations susceptibles de l'intéresser. Le Fournisseur prélève et traite dans ce but des données personnelles concernant la manière dont le Client utilise le site web, le logiciel Faji et la newsletter (s'il l’ouvre et sur quels liens il clique, p. ex.). Le Fournisseur exploite ces données à des fins statistiques dans le but de mieux adapter la newsletter à aux centres d’intérêt du Client. <br /> Le traitement des données personnelles saisies dans le formulaire d’inscription à la newsletter s’appuie sur le consentement du Client, que ce dernier peut révoquer à tout moment. La révocation s'effectue via le lien de désinscription qui se trouve dans la newsletter. Les données personnelles collectées sont nécessaires à la rédaction du contenu et à l’expédition de la newsletter. <br /> Les données personnelles collectées par le Fournisseur dans le cadre de l’inscription à la newsletter restent enregistrées jusqu’au moment de la désinscription du Client.</p>
                <h3>10. Durée de conservation</h3>
                <p>Le fournisseur traite et stocke les données personnelles du Client aussi longtemps que ce dernier utilise le service. Il convient de noter ici que la relation contractuelle est une relation continue, établie sur la base de périodes de plusieurs années.</p>
                <p>En cas de résiliation de la relation contractuelle, le Fournisseur n'a en principe pas l'obligation de stocker les données du Client. C'est pourquoi les données qui ne sont plus nécessaires sont régulièrement supprimées, à l'exception des données dont le traitement peut s'avérer nécessaire par la suite, par exemple en raison d’obligations légales de conservation ou à des fins internes absolument indispensables.</p>
                <h3>11. Droit d'accès, droit de rectification, droit de suppression, droit d'opposition, consentement</h3>
                <p>En ce qui concerne les données personnelles, les Clients disposent des droits suivants conformément à la LPD et au RGPD. Le Fournisseur accorde en principe également aux Clients suisses les droits prévus dans le RGPD. Cependant, le Fournisseur se réserve de porter une appréciation différente dans des situations spécifiques.</p>
                <ul>
                    <li>droit d’accès (art. 8 LPD, art. 15 RGPD);</li>
                    <li>droit de rectification (art. 5 par. 2 LPD, art. 16 RGPD);</li>
                    <li>droit de suppression (art. 17 du RGPD);</li>
                    <li>droit à la limitation du traitement (art. 18 RGPD);</li>
                    <li>droit de portabilité (art. 20 RGPD); </li>
                    <li>droit d’opposition (ar. 21 RGPD).</li>
                </ul>
                <p>Les droits mentionnés ci-dessus sont soumis aux restrictions éventuelles du RGPD ainsi que des lois nationales applicables respectives, qu’elles soient ou non relatives à la protection des données. <br /> Dans le cadre de la fourniture des services proposés par le Fournisseur, le Client est invité à donner son consentement à la collecte, au traitement, à la transmission et à l’utilisation de ses données personnelles par le Fournisseur en cochant la case correspondante.</p>
                <h4>Le Client peut bien évidemment retirer son consentement à tout moment sans porter atteinte à la licéité du traitement fondé sur le consentement effectué avant le retrait de celui-ci. Une demande de retrait peut être adressée par écrit à l'adresse du Fournisseur mentionnée ci-dessus, ou par e-mail à l'adresse contact@faji.com.tn . Dans ce cas, le Client ne pourra toutefois plus accéder à certains services et fonctionnalités.</h4>
                <h3>12. Liens vers d'autres sites web</h3>
                <p>Le site web du Fournisseur contient des hyperliens renvoyant vers des sites web tiers qui ne sont pas exploités ni contrôlés par lui. La responsabilité du Fournisseur ne saurait être engagée pour leur contenu ou leurs pratiques en matière de protection des données.</p>
                <h3>13. Dispositions supplémentaires pour les Clients établis dans l’espace européen</h3>
                <p>Les dispositions suivantes sont uniquement applicables pour les Clients établis dans l’espace européen. Elles ne s'appliquent pas aux Clients suisses.</p>
                <h4>Bases légales du traitement</h4>
                <p>Le traitement des données aux fins mentionnées au point 5 s’effectue conformément à l'article 6, paragraphe 1, point b) du RGPD, en vue de l’exécution du contrat. Font l’objet du contrat les services mentionnés ci-dessus. <br />Comme précisé ci-dessus, le traitement des données est également effectué dans le cadre de la protection des intérêts légitimes du Fournisseur (article 6, paragraphe 1, point f) du RGPD). Ces derniers incluent l'amélioration des produits et services (y compris la distribution de publipostages), le contrôle et l’optimisation de la performance de notre offre ainsi que la détection, la prévention et l’élucidation d'éventuelles activités illégales. <br />En outre, les données sont traitées conformément à l'article 6, paragraphe 1, point c) du RGPD afin de remplir les obligations légales du Fournisseur (obligations de conservation et de documentation, notamment). Les données de base personnelles sont ici tout particulièrement concernées. <br />Si le Client estime que l’une ou plusieurs des fins spécifiées au point 5 ne sont pas couvertes par les bases légales mentionnées ci-dessus, il peut demander au Fournisseur de ne plus traiter ses données personnelles aux fins concernées («opt-out»). Un tel retrait du consentement du Client ne l'empêche pas de continuer à utiliser le logiciel Faji, à moins que cette utilisation implique nécessairement le traitement des données en question. Le Client peut signifier au Fournisseur le retrait de son consentement par écrit à l’adresse du Fournisseur indiquée au début de la présente Politique de confidentialité ou par e-mail à l'adresse contact@faji.com.tn</p>
                <h4>Droit de recours</h4>
                <p>Si le Client estime que le traitement des données personnelles le concernant viole les dispositions du RGPD, il peut introduire une réclamation auprès d'une autorité de contrôle conformément à l'article 77 du RGPD.</p>
                <p>Le Fournisseur est bien évidemment disposé à prendre connaissance des questions et souhaits du Client avant le lancement d'une procédure formelle. Le Client peut contacter le Fournisseur par écrit ou par e-mail (contact@faji.com.tn).</p>
                <p>Dernière version: Juin 2022</p>
                <h4>Faji AG</h4>
                <h5>+216 71 808 862 <br />contact@faji.com.tn <br />FAJI Finance – 71 , Rue Alain Savary, 1003 Tunis, Tunisie.  </h5>

            </div>      <BTTButton/>

            <Footer/>
        </>
    );
}

export default ConfPolicy;
