import React from "react";
import {AiOutlineSearch } from "react-icons/ai";
import './SearchBar.css'

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  return (
    <div className="search-bar-container">
      <AiOutlineSearch className="search-icon" />
      <input
        className="search-input"
        type="text"
        placeholder="Rechercher ..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
