import React, { useEffect, useState } from "react";
import "./ContactTous.css";
import Navbar from "../../components/navbar_dash/NavbarDash";
import Footer from "../../components/footer_dash/FooterDash";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { FaBuilding } from "react-icons/fa";
import { GoPersonFill } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { TbDotsVertical } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar/SearchBar";



const Dropdown = ({
  isarchived,
  handleDesarchive,
  contactId,
  handleDropdown,
  dropdownState,
  handleArchive,
}) => {
  return (
    <div
      onClick={() => handleDropdown(contactId)}
      onMouseLeave={() => handleDropdown(null)}
    >
      <TbDotsVertical />
      {dropdownState === contactId && (
        <ul className="dropdown" style={{ flexDirection: "column" }}>
          <li>
            <Link className="navbar__link" to={`/showdetails/${contactId}`}>
              Voir Détails
            </Link>
          </li>
          <li>
            <Link className="navbar__link" to={`/editcontact/${contactId}`}>
              Modifier
            </Link>
          </li>
          <li>
            {isarchived ? (
              <button
                style={{ cursor: "pointer" }}
                className="navbar__link"
                onClick={() => handleDesarchive(contactId)}
              >
                Desarchiver
              </button>
            ) : (
              <button
                style={{ cursor: "pointer" }}
                className="navbar__link"
                onClick={() => handleArchive(contactId)}
              >
                Archiver
              </button>
            )}
          </li>
        </ul>
      )}
    </div>
  );
};

const ContactTous = () => {
  const initialSections = {
    tous: true,
    archive: false,
  };
  const [sections, setSections] = useState(initialSections);
  const [contacts, setContacts] = useState([]);
  const [selectedNonArchivedContacts, setSelectedNonArchivedContacts] =
    useState([]);
  const [selectedArchivedContacts, setSelectedArchivedContacts] = useState([]);

  const [selectAllNonArchived, setSelectAllNonArchived] = useState(false);
  const [selectAllArchived, setSelectAllArchived] = useState(false);

  const [dropdownState, setDropdownState] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [contactType, setContactType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const navigate = useNavigate();

  const toggleSection = (section) => {
    const updatedSections = { ...initialSections, [section]: true };
    for (const key in updatedSections) {
      if (key !== section) {
        updatedSections[key] = false;
      }
    }
    setSections(updatedSections);
  };

  const handleDropdown = (contactId) => {
    setDropdownState((prev) => (prev === contactId ? null : contactId));
  };

  useEffect(() => {
    axios
      .get("/api/contact")
      .then((response) => {
        setContacts(response.data);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);

  const handleSelectAll = (archived) => (event) => {
    const isChecked = event.target.checked;
    if (archived) {
      if (isChecked) {
        setSelectedArchivedContacts(
          archivedContacts.map((contact) => contact.id)
        );
      } else {
        setSelectedArchivedContacts([]);
      }
      setSelectAllArchived(isChecked);
    } else {
      if (isChecked) {
        setSelectedNonArchivedContacts(
          nonArchivedContacts.map((contact) => contact.id)
        );
      } else {
        setSelectedNonArchivedContacts([]);
      }
      setSelectAllNonArchived(isChecked);
    }
  };

  const handleCheckboxChange = (contactId, archived) => {
    if (archived) {
      if (selectedArchivedContacts.includes(contactId)) {
        setSelectedArchivedContacts(
          selectedArchivedContacts.filter((id) => id !== contactId)
        );
      } else {
        setSelectedArchivedContacts([...selectedArchivedContacts, contactId]);
      }
    } else {
      if (selectedNonArchivedContacts.includes(contactId)) {
        setSelectedNonArchivedContacts(
          selectedNonArchivedContacts.filter((id) => id !== contactId)
        );
      } else {
        setSelectedNonArchivedContacts([
          ...selectedNonArchivedContacts,
          contactId,
        ]);
      }
    }
  };

  const handleDeleteSelected = (archived) => {
    const idsToDelete = archived
      ? selectedArchivedContacts
      : selectedNonArchivedContacts;
    axios
      .delete("/api/contact/delete", {
        data: { ids: idsToDelete },
      })
      .then((response) => {
        if (response.status === 200) {
          const remainingContacts = contacts.filter(
            (contact) => !idsToDelete.includes(contact.id)
          );
          setContacts(remainingContacts);
          if (archived) {
            setSelectedArchivedContacts([]);
            setSelectAllArchived(false);
          } else {
            setSelectedNonArchivedContacts([]);
            setSelectAllNonArchived(false);
          }
        }
      })
      .catch((error) => {
        console.error("An error occurred while deleting contacts:", error);
      });
  };

  const handleArchive = (id) => {
    axios
      .put(`/api/archive/${id}`)
      .then((response) => {
        if (response.status === 200) {
          alert("Contact archivé !");
          setContacts(
            contacts.map((contact) =>
              contact.id === id ? { ...contact, archive: true } : contact
            )
          );
        }
      })
      .catch((error) => {
        console.error("An error occurred while archiving the contact:", error);
      });
  };
  const handleDesarchive = (id) => {
    axios
      .put(`/api/desarchive/${id}`)
      .then((response) => {
        if (response.status === 200) {
          alert("Contact desarchivé !");
          setContacts(
            contacts.map((contact) =>
              contact.id === id ? { ...contact, archive: false } : contact
            )
          );
        }
      })
      .catch((error) => {
        console.error(
          "An error occurred while desarchiving the contact:",
          error
        );
      });
  };
  const reset = () => {
    setContactType("");
    setCompanyName("");
    setPostalCode("");
    setCity("");
    setCountry("");
    setEmail("");
    setPhone("");
  };

  const filteredContacts = contacts.filter((contact) => {
    const matchesSearchQuery = contact.entreprise
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const matchesContactType =
      contactType === "" || contact.type_contact === contactType;
    const matchesCompanyName =
      companyName === "" ||
      contact.entreprise.toLowerCase().includes(companyName.toLowerCase());
    const matchesPostalCode =
      postalCode === "" ||
      contact.npa.toLowerCase().includes(postalCode.toLowerCase());
    const matchesCity =
      city === "" ||
      contact.localite.toLowerCase().includes(city.toLowerCase());
    const matchesCountry =
      country === "" ||
      contact.pays.toLowerCase().includes(country.toLowerCase());
    const matchesEmail =
      email === "" ||
      contact.email1.toLowerCase().includes(email.toLowerCase());
    const matchesPhone =
      phone === "" ||
      contact.phone1.toLowerCase().includes(phone.toLowerCase());

    return (
      matchesSearchQuery &&
      matchesContactType &&
      matchesCompanyName &&
      matchesPostalCode &&
      matchesCity &&
      matchesCountry &&
      matchesEmail &&
      matchesPhone
    );
  });

  const archivedContacts = filteredContacts.filter(
    (contact) => contact.archive
  );
  const nonArchivedContacts = filteredContacts.filter(
    (contact) => !contact.archive
  );
  const handleContactTypeChange = (e) => setContactType(e.target.value);
  const handleCompanyNameChange = (e) => setCompanyName(e.target.value);
  const handlePostalCodeChange = (e) => setPostalCode(e.target.value);
  const handleCityChange = (e) => setCity(e.target.value);
  const handleCountryChange = (e) => setCountry(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);

  const navigateToDetails = (contactId) => {
    navigate(`/showdetails/${contactId}`);
  };
  return (
    <>
      <Navbar />
      <div className="contact_tous">
        <div className="add_contact">
          <h1>Contacts</h1>
          <Link to="/contacttousform">Nouveau Contact</Link>
        </div>
        <div className="section1_contacttous">
          <div className="above_table_links">
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <a
              href="#"
              onClick={() => toggleSection("tous")}
              className={sections.tous ? "selected" : "not_selected"}
            >
              Tous
            </a>
            <a
              href="#"
              onClick={() => toggleSection("archive")}
              className={sections.archive ? "selected" : "not_selected"}
            >
              Archivé
            </a>
          </div>
          <hr />
          {sections.tous && (
            <div style={{ overflowX: "auto" }}>
              <table className="table_contact">
                <thead>
                  <tr>
                    <td style={{ width: "3%" }}>
                      <input
                        type="checkbox"
                        checked={selectAllNonArchived}
                        onChange={handleSelectAll(false)}
                      />
                    </td>
                    <td style={{ width: "3%" }}>N°</td>
                    <td style={{ width: "8%" }}>Type</td>
                    <td style={{ width: "16%" }}>Nom</td>
                    <td style={{ width: "8%" }}>Code postal </td>
                    <td style={{ width: "8%" }}>Ville </td>
                    <td style={{ width: "8%" }}>Pays </td>
                    <td style={{ width: "21%" }}>E-mail </td>
                    <td style={{ width: "21%" }}>Téléphone </td>
                    <td style={{ width: "3%" }}></td>
                    <td style={{ width: "3%" }}></td>
                  </tr>
                  <hr />
                  <tr>
                    <td style={{ width: "3%" }}></td>
                    <td style={{ width: "3%" }}></td>
                    <td style={{ width: "8%" }}>
                      <select
                        name="contactType"
                        value={contactType}
                        onChange={handleContactTypeChange}
                      >
                        <option value="">Tous</option>
                        <option value="Privé">Privé</option>
                        <option value="Entreprise">Entreprise</option>
                      </select>
                    </td>
                    <td style={{ width: "16%" }}>
                      <input
                        type="text"
                        placeholder="Nom de l'entreprise"
                        value={companyName}
                        onChange={handleCompanyNameChange}
                      />
                    </td>
                    <td style={{ width: "8%" }}>
                      <input
                        type="text"
                        placeholder="Code postal"
                        value={postalCode}
                        onChange={handlePostalCodeChange}
                      />
                    </td>
                    <td style={{ width: "8%" }}>
                      <input
                        type="text"
                        placeholder="Ville"
                        value={city}
                        onChange={handleCityChange}
                      />
                    </td>
                    <td style={{ width: "8%" }}>
                      <select
                        name="country"
                        value={country}
                        onChange={handleCountryChange}
                      >
                        <option value="">Tous</option>
                        <option value="Espagne">Espagne</option>
                        <option value="Italie">Italie</option>
                        <option value="Allemagne">Allemagne</option>
                        <option value="Suisse">Suisse</option>
                        <option value="Belgique">Belgique</option>
                        <option value="Tunisie">Tunisie</option>
                        <option value="France">France</option>
                      </select>
                    </td>
                    <td style={{ width: "21%" }}>
                      <input
                        type="text"
                        placeholder="E-mail"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </td>
                    <td style={{ width: "21%" }}>
                      <input
                        type="text"
                        placeholder="Téléphone"
                        value={phone}
                        onChange={handlePhoneChange}
                      />
                    </td>
                    <td style={{ width: "3%" }}></td>
                    <td style={{ width: "3%" }}>
                      <IoMdClose onClick={reset} />
                    </td>
                  </tr>
                </thead>
                <hr />
                <tbody>
                  {nonArchivedContacts.map((contact, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ width: "3%" }}>
                          <input
                            type="checkbox"
                            checked={selectedNonArchivedContacts.includes(
                              contact.id
                            )}
                            onChange={() =>
                              handleCheckboxChange(contact.id, false)
                            }
                          />
                        </td>
                        <td style={{ width: "3%" }}>{contact.id}</td>
                        <td style={{ width: "8%" }}>
                          {contact.type_contact === "Entreprise" && (
                            <FaBuilding />
                          )}
                          {contact.type_contact === "Privé" && <GoPersonFill />}
                        </td>
                        <td style={{ width: "16%" }}>{contact.entreprise}</td>
                        <td style={{ width: "8%" }}>{contact.npa}</td>
                        <td style={{ width: "8%" }}>{contact.localite}</td>
                        <td style={{ width: "8%" }}>{contact.pays}</td>
                        <td style={{ width: "21%" }}>{contact.email1}</td>
                        <td style={{ width: "21%" }}>{contact.phone1}</td>
                        <td style={{ width: "3%" }}></td>
                        <td style={{ width: "3%" }}>
                          <Dropdown
                            isarchived={contact.archive}
                            contactId={contact.id}
                            handleDropdown={handleDropdown}
                            dropdownState={dropdownState}
                            handleArchive={handleArchive}
                            handleDesarchive={handleDesarchive}
                          />
                        </td>
                      </tr>
                      <hr />
                    </React.Fragment>
                  ))}
                  <tr>
                    <td style={{ width: "3%" }}>
                      {selectedNonArchivedContacts.length !== 0 && (
                        <MdDelete
                          color="red"
                          onClick={() => handleDeleteSelected(false)}
                          size={25}
                        />
                      )}
                    </td>
                    <td style={{ width: "3%" }}></td>
                    <td style={{ width: "8%" }}></td>
                    <td style={{ width: "16%" }}></td>
                    <td style={{ width: "8%" }}></td>
                    <td style={{ width: "8%" }}></td>
                    <td style={{ width: "8%" }}></td>
                    <td style={{ width: "21%" }}></td>
                    <td style={{ width: "21%" }}></td>
                    <td style={{ width: "3%" }}></td>
                    <td style={{ width: "3%" }}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {sections.archive && (
           <div style={{ overflowX: "auto" }}>
             <table className="table_contact">
              <thead>
                <tr>
                  <td style={{ width: "3%" }}>
                    <input
                      type="checkbox"
                      checked={selectAllArchived}
                      onChange={handleSelectAll(true)}
                    />
                  </td>
                  <td style={{ width: "3%" }}>N°</td>
                  <td style={{ width: "8%" }}>Type</td>
                  <td style={{ width: "16%" }}>Nom</td>
                  <td style={{ width: "8%" }}>Code postal </td>
                  <td style={{ width: "8%" }}>Ville </td>
                  <td style={{ width: "8%" }}>Pays </td>
                  <td style={{ width: "21%" }}>E-mail </td>
                  <td style={{ width: "21%" }}>Téléphone </td>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "3%" }}></td>
                </tr>
                <hr />
                <tr>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "8%" }}>
                    <select
                      name="contactType"
                      value={contactType}
                      onChange={handleContactTypeChange}
                    >
                      <option value="">Tous</option>
                      <option value="Privé">Privé</option>
                      <option value="Entreprise">Entreprise</option>
                    </select>
                  </td>
                  <td style={{ width: "16%" }}>
                    <input
                      type="text"
                      placeholder="Nom de l'entreprise"
                      value={companyName}
                      onChange={handleCompanyNameChange}
                    />
                  </td>
                  <td style={{ width: "8%" }}>
                    <input
                      type="text"
                      placeholder="Code postal"
                      value={postalCode}
                      onChange={handlePostalCodeChange}
                    />
                  </td>
                  <td style={{ width: "8%" }}>
                    <input
                      type="text"
                      placeholder="Ville"
                      value={city}
                      onChange={handleCityChange}
                    />
                  </td>
                  <td style={{ width: "8%" }}>
                    <select
                      name="country"
                      value={country}
                      onChange={handleCountryChange}
                    >
                      <option value="">Tous</option>
                      <option value="Espagne">Espagne</option>
                      <option value="Italie">Italie</option>
                      <option value="Allemagne">Allemagne</option>
                      <option value="Suisse">Suisse</option>
                      <option value="Belgique">Belgique</option>
                      <option value="Tunisie">Tunisie</option>
                      <option value="France">France</option>
                    </select>
                  </td>
                  <td style={{ width: "21%" }}>
                    <input
                      type="text"
                      placeholder="E-mail"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </td>
                  <td style={{ width: "21%" }}>
                    <input
                      type="text"
                      placeholder="Téléphone"
                      value={phone}
                      onChange={handlePhoneChange}
                    />
                  </td>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "3%" }}>
                    <IoMdClose />
                  </td>
                </tr>
              </thead>
              <hr />
              <tbody>
                {archivedContacts.map((contact, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ width: "3%" }}>
                        <input
                          type="checkbox"
                          checked={selectedArchivedContacts.includes(
                            contact.id
                          )}
                          onChange={() =>
                            handleCheckboxChange(contact.id, true)
                          }
                        />
                      </td>
                      <td style={{ width: "3%" }}>{contact.id}</td>
                      <td style={{ width: "8%" }}>
                        {contact.type_contact === "Entreprise" ? (
                          <FaBuilding />
                        ) : (
                          <GoPersonFill />
                        )}
                      </td>
                      <td style={{ width: "16%" }}>{contact.entreprise}</td>
                      <td style={{ width: "8%" }}>{contact.npa}</td>
                      <td style={{ width: "8%" }}>{contact.localite}</td>
                      <td style={{ width: "8%" }}>{contact.pays}</td>
                      <td style={{ width: "21%" }}>{contact.email1}</td>
                      <td style={{ width: "21%" }}>{contact.phone1}</td>
                      <td style={{ width: "3%" }}></td>
                      <td style={{ width: "3%" }}>
                        <Dropdown
                          isarchived={contact.archive}
                          contactId={contact.id}
                          handleDropdown={handleDropdown}
                          dropdownState={dropdownState}
                          handleArchive={handleArchive}
                          handleDesarchive={handleDesarchive}
                        />
                      </td>
                    </tr>
                    <hr />
                  </React.Fragment>
                ))}
                <tr>
                  <td style={{ width: "3%" }}>
                    {selectedArchivedContacts.length !== 0 && (
                      <MdDelete
                        color="red"
                        onClick={() => handleDeleteSelected(true)}
                        size={25}
                      />
                    )}
                  </td>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "8%" }}></td>
                  <td style={{ width: "16%" }}></td>
                  <td style={{ width: "8%" }}></td>
                  <td style={{ width: "8%" }}></td>
                  <td style={{ width: "8%" }}></td>
                  <td style={{ width: "21%" }}></td>
                  <td style={{ width: "21%" }}></td>
                  <td style={{ width: "3%" }}></td>
                  <td style={{ width: "3%" }}></td>
                </tr>
              </tbody>
            </table>
           </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactTous;
